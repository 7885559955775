import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Dictionary from "./utils/Dictionary";

// 设置全局数据字典
Vue.prototype.$dictionary = Dictionary;

// 引入全局CSS样式
import "./assets/element-ui/lib/theme-chalk/index.css";
import "./assets/css/animate.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/iconFonts/iconfont.css";
import "./assets/css/base.scss";

import Axios from "@/utils/axios";
Vue.prototype.$Axios = Axios;

// 集成 Element UI
import ELEMENT from "element-ui";
// import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ELEMENT);

// 集成i18n 国际化资源
import i18n from "./i18n";

// 集成 VXE-Table
import VXETable from "./vxe-table";

import "./components/VxeTable/index";
Vue.use(VXETable);
Vue.prototype.$VXETable = VXETable;


import XEUtils from "xe-utils";
Vue.prototype.$XEUtils = XEUtils;

import Tools from "@/utils/Tools";
Vue.prototype.$Tools = Tools;

// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// // 全局注册自有组件
// Object.keys(components).forEach((key) => {
//   var name = key.replace(/(\w)/, (v) => v.toUpperCase())
//   Vue.component(`v${name}`, components[key])
// })

//动态获取国际化资源
Vue.use(VXETable, {
    // 对组件内置的提示语进行国际化翻译
    i18n: (key) => i18n.t(key),
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");