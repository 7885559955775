<template>
<div class="wrapper">
  <div class="search" v-if="searchOptions.items && searchOptions.items.length">
    <vxe-form v-bind="searchOptions" @submit="searchEvent" @reset="searchResetEvent"></vxe-form>
  </div>
  <div class="table">
    <vxe-grid ref="xGrid" height="auto" v-bind="gridOptions" :loading="loading" :data="tableData" @toolbar-button-click="toolbarCustomEvent" v-on="gridOptions.events"></vxe-grid>
  </div>
  <vxe-modal ref="xModal" v-bind="formOptions" :title="formData.id ? $t('editAndSave') : $t('createAndSave')" show-zoom>
    <template v-slot>
      <vxe-form ref="xForm" v-bind="formOptions" :data="formData" v-loading="loading" @submit="submitEvent" @reset="closeModal(false)"></vxe-form>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from "vuex";
import XEUtils from "xe-utils";
export default {
  props: {
    service: String,
    defaultProps: {
      type: Object,
      default: () => {
        return {
          value: "code",
          label: "name",
        };
      },
    },
    gridOptions: {
      type: Object,
      default: () => {
        return {
          columns: [],
          data: {}
        };
      },
    },
    formOptions: {
      type: Object,
      default: () => {
        return {
          width: "1000",
          titleWidth: "100",
          items: [],
          data: {}
        };
      },
    },
    searchOptions: {
      type: Object,
      default: () => {
        return {
          items: [],
          data: {}
        };
      },
    },
    // 是否默认加载数据 FALSE 为默认加载,TRUE 为外界触发加载
    defaultTrigger: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {
      serviceUrl: "",
      loading: false,
      dictTypeCodes: [],
      tableData: [],
      originData: [],
      formData: {},
    };
  },
  created() {
    this.serviceUrl = this.service || this.$route.meta.service;
    let { toolbarConfig, editRules } = this.gridOptions;
    let permissButtons = this.$Tools.getBtnPermiss(this.$route);
    toolbarConfig.buttons = toolbarConfig.buttons || [
      { code: 'create', name: '新增', status: 'primary' },
      { code: 'setAllTreeExpand', name: '全部展开', status: 'success' },
      { code: 'clearTreeExpand', name: '全部收起', status: 'success' },
    ]
    toolbarConfig.buttons.map(btn => {
      let node = XEUtils.filterTree(permissButtons, item => item.code === `${this.$route.name}.${btn.code}`)[0];
      if (node) {
        btn.name = node.name || btn.name;
        btn.visible = true
      } else {
        btn.visible = false;
      }
    });
    let { rules } = this.formOptions;
    // 根据新增功能中的字段增加表格中的校验
    this.gridOptions.editRules = editRules || rules;
    // 获取当前页面所需要的数据字典key
    this.getSysDictEvent();
    if (this.defaultTrigger) {
      this.getLists();
    }
  },
  methods: {
    ...mapActions([
      "getSysDictService",
      "getTreeService",
      "removeEventService",
      "submitEventService",
      "queryEventService",
      "exportAllEventService",
    ]),

    // 获取字典key
    getTypeCodeEvent(dicts, columns) {
      columns.forEach(item => {
        let render = item.itemRender || item.editRender || item.cellRender;
        if (render && render.optionTypeCode && dicts.indexOf(render.optionTypeCode) == -1) {
          dicts.push(render.optionTypeCode);
        }
        if (item.children && item.children.length) {
          this.getTypeCodeEvent(dicts, item.children)
        }
      });
      return dicts;
    },

    // 获取数据字典
    getSysDictEvent() {
      let dictTypeCodes = this.getTypeCodeEvent([], [...this.gridOptions.columns, ...this.formOptions.items, ...this.searchOptions.items]);
      if (!dictTypeCodes.length) {
        return;
      }
      this.getSysDictService({
          params: dictTypeCodes
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.$Tools.buildDataByDicts(this.gridOptions.columns, data, this.defaultProps);
            this.$Tools.buildDataByDicts(this.formOptions.items, data, this.defaultProps);
            this.searchOptions.items = [...this.$Tools.buildDataByDicts(this.searchOptions.items, data, this.defaultProps)];
          }
        });
    },

    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({
        service: this.serviceUrl,
        params: this.searchOptions.data || {},
      }).then(({ code, data, dicts }) => {
        if (code == 200) {
          // 不需要转换的接口
          let arrs = ["/sys/sysAppResource", "/fee/fPayFeeDetail"]
          let tableData = arrs.indexOf(this.serviceUrl) > -1 ? data : this.$XEUtils.toArrayTree(data)
          this.tableData = tableData || [];
          this.originData = tableData || [];
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    // 列表按钮组功能通用事件
    toolbarCustomEvent({ code, button }) {
      const { xGrid } = this.$refs;
      if (button.events) {
        Object.keys(button.events).forEach((key, index) => {
          if (index == 0) button.events[key](button);
        });
      } else {
        switch (code) {
          case "create":
            this.insertEvent();
            break;
          case "setAllTreeExpand":
            xGrid.setAllTreeExpand(true);
            break;
          case "clearTreeExpand":
            xGrid.clearTreeExpand();
            break;
          default:
            if (button.events) {
              Object.keys(button.events).forEach((key, index) => {
                if (index == 0) button.events[key](button);
              });
            }
            break;
        }
      }
    },

    // 新增事件
    insertEvent() {
      this.formData = { ...this.formOptions.data };
      this.$refs.xModal.open();
    },

    // 编辑事件
    queryEvent(row) {
      this.loading = true;
      this.queryEventService({
          service: this.serviceUrl,
          params: row,
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.formData = data;
            this.$refs.xModal.open();
          }
          this.resetEvent();
        })
        .catch((err) => {
          this.resetEvent();
        });
    },

    // 保存提交事件
    async submitEvent(row) {
      if (row.data) {
        this.$refs["xForm"].validate((valid) => {
          if (!valid) {
            this.submitData(row.data)
          }
        });
      } else {
        const errMap = await this.$refs.xGrid.validate(row).catch(errMap => errMap);
        if (errMap) {
          this.$message.error("请完善数据内容");
        } else {
          this.$refs.xGrid.clearActived().then(() => {
            this.submitData(row)
          })
        }
      }
    },

    // 提交数据
    submitData(row) {
      if (this.loading) return;
      this.loading = true;
      this.submitEventService({
        service: this.serviceUrl,
        params: row,
      }).then(({ code, data }) => {
        if (code == 200) {
          // 动态设置菜单开启权限
          if (this.serviceUrl == "/sys/sysAppResource") {
            localStorage.resources = JSON.stringify(data);
            this.$store.state.menuLists = data;
          }
          this.getLists();
          this.$message.success(row.id ? "更新成功" : "保存成功");
          this.closeModal();
        } else {
          this.$refs.xGrid.setActiveRow(row)
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    // 取消事件
    cancelEvent(row) {
      this.$refs.xGrid.clearActived().then(() => {
        this.$refs.xGrid.revertData();
      });
    },

    // 搜索功能
    // 创建一个防反跳策略函数，调用频率间隔 500 毫秒
    // 搜索功能
    searchEvent: XEUtils.debounce(function () {
      this.handleSearch()
    }, 500, {
      leading: false,
      trailing: true
    }),

    handleSearch() {
      this.loading = true;
      let keyWords = XEUtils.toString(this.searchOptions.data.keyWords).trim();
      if (keyWords) {
        let options = {
          children: 'children'
        }
        let searchProps = this.searchOptions.searchProps || [];
        this.tableData = XEUtils.searchTree(this.originData, item => searchProps.some(key => XEUtils.toString(item[key]).indexOf(keyWords) > -1), options)
        // 搜索之后默认展开所有子节点
        this.$nextTick(() => {
          this.$refs.xGrid.setAllTreeExpand(true);
        })
      } else {
        this.tableData = this.originData
      }
      this.loading = false;
    },

    // 搜索重置功能
    searchResetEvent() {
      this.getLists();
    },

    // 关闭模态对话框
    closeModal(bool) {
      if (bool) this.getLists();
      if (this.$refs.xForm) {
        this.$refs.xForm.reset();
      }
      if (this.$refs.xModal) {
        this.$refs.xModal.close();
      }
    },

    // 批量删除
    batchDeleteEvent() {
      let records = this.$refs.xGrid.getCheckboxRecords();
      if (!records.length) {
        this.$message.error(this.$t("pleaseSelectDataToDelete"));
      } else {
        let ids = [];
        records.forEach((item) => {
          ids.push(item.id);
        });
        this.removeEvent(ids);
      }
    },

    // 删除事件
    removeEvent(ids) {
      this.$confirm(this.$t("isDelete"), this.$t("confirmTitle")).then(
        (type) => {
          if (type === "confirm") {
            this.loading = true;
            this.removeEventService({
                service: this.serviceUrl,
                params: ids,
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$message.success(this.$t("deleteSuccess"));
                  this.getLists();
                  this.resetEvent();
                }
                this.resetEvent();
              })
              .catch((err) => {
                this.resetEvent();
              });
          }
        }
      );
    },

    resetEvent() {
      this.loading = false;
    },

    // 批量导出
    exportAllEvent(options) {
      this.exportAllEventService({
        service: this.serviceUrl,
        params: {
          condition: this.data,
          exportColumns: options.columns,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
