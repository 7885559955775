const Logos = [{
        name: "安掌门",
        code: "azm",
        origin: process.env.VUE_APP_BASEURL,
        bigLogo: "/static/logo/azm/bigLogo.png",
        smallLogo: "/static/logo/azm/smallLogo.png",
    },
    {
        name: "正能物业",
        code: "znwy",
        origin: "https://znwy.snznkc.com",
        bigLogo: "/static/logo/znwy/bigLogo.png",
        smallLogo: "/static/logo/znwy/smallLogo.png",
    },
    {
        name: "西房",
        code: "xf",
        origin: "https://mbl.xatali.com",
        bigLogo: "/static/logo/xf/bigLogo.png",
        smallLogo: "/static/logo/xf/smallLogo.png",
    },
    {
        name: "易地无忧",
        code: "ydwy",
        origin: "https://wysq.yidiwuyou.com.cn",
        bigLogo: "/static/logo/ydwy/bigLogo.png",
        smallLogo: "/static/logo/ydwy/smallLogo.png",
    }
]
export default Logos