<template>
<div style="padding: 0 0 10px 30px">
  <p style="padding:10px 0">
    {{data.code}}
    {{data.code?'. ':''}}
    {{data.title}}
  </p>

  <div v-if="data.type == 1">
    <vxe-input :placeholder="data.placeholder" disabled></vxe-input>
  </div>
  
  <div v-if="data.type == 2">
    <vxe-radio-group>
      <div v-for="(item,index) in data.optionList" :key="index" class="item_box">
        <vxe-radio :label="item.value" :content="`${$dictionary.serialNumber[index]}：${item.label}`">
        </vxe-radio>
      </div>
    </vxe-radio-group>
  </div>

  <div v-if="data.type == 3">
    <vxe-checkbox-group>
      <div v-for="(item,index) in data.optionList" :key="index" class="item_box">
        <span>
          {{ $dictionary.serialNumber[index]}}：
        </span>
        <vxe-checkbox :label="item.value" :content="item.label">
        </vxe-checkbox>
      </div>
    </vxe-checkbox-group>
  </div>

  <div v-if="data.type == 4">
    <vxe-textarea :placeholder="data.placeholder" disabled></vxe-textarea>
  </div>
</div>
</template>

<script>
export default {
  name: 'VxeFormPreview',
  props: {
    params: Object,
    type: String
  },
  data() {
    return {}
  },
  computed: {
    data() {
      return this.params.data
    }
  }
};
</script>

<style lang="scss" scoped>
.item_box {
  display: block;
  height: 34px;
  line-height: 34px;
}
</style>
