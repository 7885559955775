<template>
<div>
  <span v-if="type != 'renderCell'">
    <vxe-pulldown ref="xDown" transfer>
      <template #default>
        <vxe-input v-model="value" suffix-icon="fa fa-search" :disabled="props.disabled" :placeholder="props.placeholder" @focus="focusEvent" @clear="clearEvent"></vxe-input>
      </template>
      <template #dropdown>
        <div class="dropdown">
          <el-tree ref="tree" :data="options" :props="defaultProps" :default-expand-all="expandAll" :filter-node-method="filterNode" :expand-on-click-node="false" v-loading="loading" @node-click="getTreeNode"></el-tree>
        </div>
      </template>
    </vxe-pulldown>
  </span>
  <span v-else>
    {{ currNode.label }}
  </span>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "VxeSelectTree",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      defaultProps: {
        label: "name",
        value: "id",
      },
      options: [],
      expandAll: false,
      loading: false,
      ...this.renderOpts,
    };
  },
  watch: {
    value(val) {
      if (this.$refs.tree) {
        this.$refs.tree.filter(val);
      }
    },
  },
  computed: {
    value: {
      get() {
        let { row, column, data, property } = this.params;
        let nodeId = this.type == "renderItemContent" ? data[property] : row[column.property];
        let currNode = this.$Tools.getNodeById(this.options, nodeId) || {};
        return currNode.label || ""
      },
      set(newValue) {
        let { row, column, data, property } = this.params;
        if (this.type == "renderItemContent") {
          this.$set(data, property, newValue[this.defaultProps.value]);
        } else {
          this.$set(row, column.property, newValue[this.defaultProps.value]);
        }
      }
    },

    currNode() {
      let { row, column, data, property } = this.params;
      let nodeId = this.type == "renderItemContent" ? data[property] : row[column.property];
      return this.$Tools.getNodeById(this.options, nodeId) || {};
    },
  },
  created() {
    if (this.service) {
      this.getLists();
    }
  },
  methods: {
    ...mapActions(['getTreeService']),

    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({
        service: this.service,
        params: this.defaultParams || {}
      }).then(({ code, data, message }) => {
        if (code == 200) {
          if (this.service == "/sys/sysDictType") {
            this.options = data;
          } else {
            this.options = this.$Tools.treeDataEvent(this.$XEUtils.toArrayTree(data), this.defaultProps) || [];
          }
        }
        this.resetEvent();
      });
    },
    focusEvent() {
      this.$refs.xDown.showPanel();
    },

    // 节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    // 获取节点
    getTreeNode(node) {
      // 触发外部事件
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, node);
        });
      }
      this.$refs.xDown && this.$refs.xDown.hidePanel();
    },

    clearEvent() {
      let { row, column, data, property } = this.params;
      if (this.type == "renderItemContent") {
        data[property] = null;
      } else {
        row[column.property] = null;
      }
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, {});
        });
      }
    },
    resetEvent() {
      this.loading = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  width: 100%;
  padding: 5px 0;
  max-height: 200px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);

  .table {
    padding: 0;
  }
}
</style>
