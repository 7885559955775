<template>
<div>
  <vxe-button :type="renderOpts.props.type" :status="renderOpts.props.status" :content="value" @click="clickEvent" class="button"></vxe-button>
  <vxe-modal ref="xModal" :title="`订单详情  ( ${params.row.orderId}  订单金额：${params.row.totalAmount} )`" width="1000" height="600">
    <template v-slot>
      <div class="main">
        <div class="main_right">
          <VxeBasicTable ref="xGrid" :service="renderOpts.props.service" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
          </VxeBasicTable>
          <div class="total_box" v-show="total">
            总计退费： {{total}}
          </div>
        </div>
      </div>
    </template>
  </vxe-modal>
  <vxe-modal v-model="isShowApplicationModal" title="申请退费" width="600" resize remember>
    <template #default>
      <vxe-form :data="formData" :rules="rules" title-width="100">
        <vxe-form-item title="退费原因" field="remark" span="24">
          <template #default>
            <vxe-textarea v-model="formData.remark" maxlength="300"></vxe-textarea>
          </template>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary" @click="submitApplication">提交申请</vxe-button>
            <vxe-button type="reset" @click="isShowApplicationModal = false">取消申请</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </template>
  </vxe-modal>

</div>
</template>

<script>
import { mapActions } from 'vuex';
import XEUtils from "xe-utils";
export default {
  name: "VxeOrderInfo",
  props: {
    type: String,
    renderOpts: {
      type: Object,
      default: () => {
        return {}
      }
    },
    params: Object,
  },
  data() {
    return {
      total: '',
      isShowModal: false,
      isShowApplicationModal: false,
      formData: {
        remark: ''
      },
      rules: {
        refundExplain: [
          { required: true, message: "请输入退费原因" },
          { min: 2, max: 30, message: "长度在 2 到 300 个字符" }
        ]
      },
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            field: "feeMonth",
            title: "计费期",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入计费期"
              }
            }
          },
          {
            field: "configName",
            title: "项目名称",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "costType",
              props: {
                type: 'text',
                status: "primary",
              },
            }
          },
          {
            field: "feeTypeId",
            title: "收费类型",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "costType",
              props: {
                type: 'text',
                status: "primary",
              },
            }
          },
          {
            field: "receivableAmount",
            title: "应收金额",
            minWidth: 160,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return XEUtils.commafy(Number(cellValue), { digits: 3 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入应收金额"
              }
            }
          },
          {
            field: "discountDetail",
            title: "优惠金额",
            formatter: ({ cellValue }) => {
              if (!cellValue) {
                return "0.00"
              } else {
                return this.$XEUtils.commafy(Number(cellValue), { digits: 2 })
              }
            },
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                digits: 2,
                maxlength: 24,
                placeholder: "请输入优惠金额"
              }
            }
          },
          {
            field: "receivedAmount",
            title: "实收金额",
            minWidth: 160,
            formatter: ({ cellValue, row, column }) => {
              if (!cellValue) {
                return "/"
              } else {
                return XEUtils.commafy(Number(cellValue), { digits: 3 })
              }
            },
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,

                maxlength: 24,
                placeholder: "请输入实收金额"
              }
            }
          },
          {
            field: "orderInfoStatus",
            title: "订单状态",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: this.renderOpts.props.optionCode,
            }
          },
          // {
          //   field: "refundExplain",
          //   title: "退费原因",
          //   minWidth: 160,
          //   editRender: {
          //     name: "$input",
          //   }
          // }
        ],
        editConfig: {
          enabled: false
        },
        exportConfig: {},
        importConfig: {},
        checkboxConfig: {
          checkMethod: ({ row }) => {
            return row.orderInfoStatus == 30
          },
        }
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [],
        //表单校验规则
        rules: {

        },
        // 新增功能字段初始化
        data: {},
      },

      // 查询功能字段配置
      searchOptions: {
        items: [],
        data: {
          orderId: "",
          id: ""
        },
      },
    };
  },
  computed: {
    value() {
      let { row, column } = this.params;
      return `${row[column.property]}`
    }
  },
  methods: {
    clickEvent() {
      let { row } = this.params;
      this.searchOptions.data.orderId = row.id;
      this.searchOptions.data.id = row.id;
      this.$refs.xModal.open();
    },

    ...mapActions(['submitEventService', 'postEventService']),
    applicationRefund() {
      let records = this.$refs.xGrid.$refs.xGrid.getCheckboxRecords();
      if (!records.length) {
        this.$message.error('请先勾选需要退费的订单')
      } else {
        this.calculatedTotal()
        this.isShowApplicationModal = true
      }
    },
    calculatedTotal() {
      let records = this.$refs.xGrid.$refs.xGrid.getCheckboxRecords();
      let total = 0
      records.forEach(item => {
        total = XEUtils.add(total, item.receivedAmount)
      });
      this.total = XEUtils.commafy(Number(total), { digits: 2 })
    },
    submitApplication() {
      if (!this.formData.remark) {
        return
      }
      let records = this.$refs.xGrid.$refs.xGrid.getCheckboxRecords();
      this.postEventService({
        service: "/order/orderRefund/",
        params: {
          orderId: this.params.row.id,
          remark: this.formData.remark,
          detailIds: records.map(item => {
            return item.id
          })
        },
      }).then(({ code, message }) => {
        if (code == 200) {
          this.$message.success('申请成功')
          this.isShowApplicationModal = false
        } else {
          this.$message.error(message)
        }
        this.$refs.xGrid.getLists();
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.button {
  text-decoration: underline !important;
}

.total_box {
  position: absolute;
  top: 15px;
  right: 170px;
  z-index: 9;
  color: #000;
  font-size: 16px;
}
</style>
