<template>
<div>
  <vxe-button :content="renderOpts.props.content" :status="renderOpts.props.status" @click="clickEvent"></vxe-button>
  <vxe-modal ref="xModal" title="选择职工" width="800" height="600" show-zoom :mask="false">
    <template v-slot>
      <div class="container">
        <VxeBasicTable ref="xGrid" service="/wisdom/inspectionPoint" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
        </VxeBasicTable>
      </div>
    </template>
  </vxe-modal>
</div>
</template>

<script>
export default {
  name: "ToolbarSelectInspectionPoint",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      value: "",
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            field: "inspectionName",
            title: "巡检名称",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检名称",
              },
            },
          },
          {
            field: "inspectionPlace",
            title: "巡检位置",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请选择巡检位置",
              },
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 100,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                status: "primary",
                label: "选择",
                eventName: "customEvent",
                events: {
                  click: (row) => {
                    this.selectEvent(row);
                  }
                }
              }]
            },
          },
        ],
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 130,
        mask: false,
        items: [],
        //表单校验规则
        rules: {},
        // 新增功能字段初始化
        data: {},
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "inspectionName",
            title: "巡检名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检名称",
              },
            },
          },
          {
            field: "inspectionPlace",
            title: "巡检位置",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入巡检位置",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          inspectionPlace: "",
          inspectionName: ""
        },
      },
    }
  },
  created() {
    this.searchOptions.data["parentId"] = this.renderOpts.params.communityId;
    this.searchOptions.data["inspectionRouteId"] = this.renderOpts.params.parentId;
  },
  methods: {
    clickEvent() {
      this.$refs.xModal.open();
    },

    selectEvent(row) {
      this.$confirm("是否选择当前巡检点？", this.$t("confirmTitle")).then((type) => {
        if (type === "confirm") {
          this.value = row.name;
          this.$refs.xModal.close();
          let { events } = this.renderOpts
          if (events) {
            Object.keys(events).forEach((key, index) => {
              if (index == 0) events[key](this.renderOpts, row);
            });
          }
        }
      });
    },

    resetEvent() {
      this.loading = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  box-sizing: border-box;
}
</style>
