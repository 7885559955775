// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icon_photo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".upload,.uploadImg{width:150px;overflow:hidden}.uploadImg{height:150px}.hide{overflow:hidden}.hide .el-upload--picture-card{display:none}.file{overflow:hidden}.uploadBtn{text-align:center;padding:10px;box-sizing:border-box}.camera{text-align:center;position:relative}.camera_outer{padding:10px;overflow:hidden;margin:0 auto}.camera_outer,.picture{width:300px;position:relative}.picture{background:#fff}.scaleX{transform:scaleX(-1)}.video{width:300px;height:300px;overflow:hidden;border:1px dashed #d9d9d9;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;background-size:150px auto}.img_bg_camera{width:302px;height:302px;overflow:hidden;position:absolute;left:10px;top:10px;z-index:99;background:#fff;margin:0 auto}.button_list{display:flex;justify-content:space-around;padding:20px}.preview{padding:10px;text-align:center}.preview .el-image{width:100%}", ""]);
// Exports
module.exports = exports;
