<template>
<vxe-pulldown ref="xDown" transfer>
  <template #default>
    <vxe-input v-model="currNode.value" suffix-icon="fa fa-search" :placeholder="placeholder" @keyup="keyupEvent" @click="focusEvent" @suffix-click="suffixClick"></vxe-input>
  </template>
  <template #dropdown>
    <div class="dropdown">
      <vxe-grid ref="xGrid" height="auto" v-bind="gridOptions" :loading="loading" :data="tableData" @cell-click="cellClickEvent"></vxe-grid>
    </div>
  </template>
</vxe-pulldown>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "VxeSelectTable",
  props: {
    renderOpts: {
      type: Object,
      default: () => {
        return {
          value: "value",
          label: "label",
        }
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return "请选择"
      }
    },
    params: Object,
    type: String,
  },
  data() {
    return {
      tableData: [],
      filterText: '',
      currNode: {},

      gridOptions: {
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            field: "name",
            title: "企业名称",
            minWidth: 160,
            treeNode: true,
            align: "left",
            editRender: {
              name: "$input",
            },
          },
          {
            field: "code",
            title: "企业代码",
            minWidth: 160,
            editRender: {
              name: "$input",
            },
          },
          {
            field: "type",
            title: "简称",
            minWidth: 160,
            editRender: {
              name: "$input",
            },
          },
          {
            field: "status",
            title: "状态",
            minWidth: 160,
            editRender: {
              name: "$input",
            },
          },
        ],
        treeConfig: {
          children: "children",
          accordion: false,
        },
        exportConfig: {},
        importConfig: {},
      },
    };
  },
  computed: {
    ...mapState(["dictionaries"]),
    // tableData() {
    //   return this.dictionaries[this.renderOpts.service];
    // },
    // placeholder() {
    //   return this.renderOpts.props && this.renderOpts.props.placeholder || "请选择"
    // },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    if (this.type == "renderItemContent") {
      this.getLists();
    } else {
      let nodeId = this.type == "renderItemContent" ? this.params.data[this.params.property] : this.params.row[this.params.column.property];
      this.currNode = this.$Tools.getNodeById(this.renderOpts.options, nodeId) || {};
    }
  },
  methods: {
    ...mapActions(['getTreeService']),

    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({
        service: this.renderOpts.service,
        params: {}
      }).then(({ code, data, message }) => {
        if (code == 200) {
          this.tableData = this.$Tools.treeDataEvent(data, this.renderOpts.defaultProps);
          let nodeId = this.type == "renderItemContent" ? this.params.data[this.params.property] : this.params.row[this.params.column.property];
          this.currNode = this.$Tools.getNodeById(this.tableData, nodeId) || {};
          this.loading = false
        }
      });
    },

    focusEvent() {
      this.$refs.xDown.showPanel()
    },
    keyupEvent() {
      const { value } = this
      this.loading = true
      setTimeout(() => {
        this.loading = false
        if (value) {
          this.tableData4 = this.tableList4.filter(row => row.name.indexOf(value) > -1)
        } else {
          this.tableData4 = this.tableList4.slice(0)
        }
      }, 100)
    },
    suffixClick() {
      // this.$refs.xDown.togglePanel()
    },
    cellClickEvent({ row }) {
      this.renderOpts.events.click(row);
      this.$refs.xDown.hidePanel()
    },
    pageChangeEvent({ currentPage, pageSize }) {
      this.tablePage4.currentPage = currentPage
      this.tablePage4.pageSize = pageSize
    },

    // 节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    // 获取节点
    getTreeNode(node) {
      if (this.type == "renderItemContent") {
        this.params.data[this.params.property] = node.id;
      } else {
        this.params.row[this.params.column.property] = node.id;
      }
      this.currNode = node;
      this.$refs.xModal && this.$refs.xModal.close();
    },

    clearEvent({ value, $event }) {
      if (this.type == "renderItemContent") {
        this.params.data[this.params.property] = 0;
      } else {
        this.params.row[this.params.column.property] = 0;
      }
    },
  }
};
</script>

<style lang="scss">
.dropdown {
  width: 100%;
  height: 300px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);

  .table {
    padding: 0;
  }

}
</style>
