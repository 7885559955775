<template>
<div class="tree">
  <span>
    <vxe-pulldown ref="xDown" transfer>
      <template #default>
        <vxe-input v-model="orgValue" readonly placeholder="请选择企业" @focus="focusEvent" :clearable="false"></vxe-input>
      </template>
      <template #dropdown>
        <div class="dropdown">
          <el-tree ref="orgTree" :data="orgLists" :props="defaultProps" default-expand-all :expand-on-click-node="false" v-loading="loading" @node-click="getTreeNode"></el-tree>
        </div>
      </template>
    </vxe-pulldown>
  </span>
  <span>
    <vxe-select v-model="communityValue" placeholder="请选择小区" :option-props="optionProps" :options="communityLists" @change="changeEvent"></vxe-select>
  </span>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "VxeCommunityTree",
  props: {
    service: String, // 接口地址
    placeholder: {
      type: String,
      default: () => {
        return "请输入关键字";
      },
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "children",
          label: "name",
        };
      },
    },
    renderOpts: Object,
    params: {
      type: Object,
      default: () => {
        return {
          enabled: "1"
        };
      },
    },
  },
  data() {
    return {
      optionProps: {
        value: "id",
        label: "name",
      },
      loading: false,
      treeData: [],
      currNode: {},
      orgLists: [],
      orgValue: '',
      communityLists: [],
      communityValue: "",
    };
  },
  created() {
    this.getLists();
  },
  methods: {
    ...mapActions(["getTreeService"]),
    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({
        service: "/sys/sysOrganization/treeQueryAuthCommon/communtiy",
        params: {},
      }).then(({ code, data }) => {
        if (code == 200) {
          this.treeData = this.$XEUtils.toArrayTree(data, { strict: true });
          this.orgLists = this.getOrgEvent(this.treeData);
          if (this.treeData.length) {
            let currNode = this.treeData[0];
            if (data[0].type == 1) {
              this.getTreeNode(currNode.children[0])
            } else {
              this.getTreeNode(currNode)
            }
          }
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    resetEvent() {
      this.loading = false;
    },
    // 组织机构树
    getOrgEvent(tree, arr = []) {
      tree.forEach(item => {
        let node = { ...item, children: [] }
        if (item.type != '1001') arr.push(node);
        if (item.children && item.children.length && item.type != '1001') {
          this.getOrgEvent(item.children, node.children)
        }
      });
      return arr;
    },
    getCommunityEvent(tree, id, arr = []) {
      tree.forEach(item => {
        if (item.children && item.children.length && item.id != id) {
          this.getCommunityEvent(item.children, id, arr)
        } else {
          if (item.children && item.children.length) {
            item.children.forEach(item => {
              if (item.type == '1001') {
                arr.push(item)
              }
            })
          }
        }
      });
      return arr;
    },

    focusEvent() {
      this.$refs.xDown.showPanel();
    },
    // 获取组织树节点
    getTreeNode(node) {
      this.currNode = node;
      this.orgValue = node.name;
      this.communityLists = this.getCommunityEvent(this.treeData, node.id);
      if (this.communityLists.length) {
        this.changeEvent({
          value: this.communityLists[0].id
        })
      } else {
        this.communityValue = "";
      }
      this.$refs.xDown && this.$refs.xDown.hidePanel();
    },
    // 获取节点
    changeEvent({ value }) {
      this.communityValue = value;
      let { data, property } = this.params;
      data[property] = value;
      this.$parent.$parent.$parent.searchEvent(this.params);
    },
    styleMap(status) {
      let mapObj = {
        '0': '#000',
        '1': '#ccc',
        '2': '#122d78',
        '3': '#26d116',
        '4': '#0cb5d3',
        '5': '#e07918',
      }
      if (status || status == '0') {
        return 'color:' + mapObj[status]
      } else {
        'color: red'
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tree {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  span {
    margin-right: 10px;
  }

  .vxe-input {
    width: 100%;
  }

  .treeNode {
    font-size: 14px;

    .iconfont {
      font-size: 14px;
    }
  }
}

.dropdown {
  width: 100%;
  padding: 5px 0;
  max-height: 200px;
  overflow: auto;
  background: #fff;
  box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>
