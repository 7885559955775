// 引入vxe-table
import Vue from "vue";
import Router from "@/router";
import Tools from "@/utils/Tools";
import XEUtils from 'xe-utils'
import VXETable from "vxe-table";
import "vxe-table/lib/style.min.css";
// 样式
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'

// 集成XLSX导入导出插件
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
VXETable.use(VXETablePluginExportXLSX)

VXETable.use(VXETablePluginElement)

Vue.use(XEUtils)
Vue.use(VXETable)


VXETable.setup({
  icon: {
    // select
    SELECT_OPEN: "el-icon-arrow-down rotate180",
    SELECT_CLOSE: "el-icon-arrow-down",
  },
  // size: null, // 全局尺寸
  zIndex: 1000, // 全局 zIndex 起始值，如果项目的的 z-index 样式值过大时就需要跟随设置更大，避免被遮挡
  // version: 0, // 版本号，对于某些带数据缓存的功能有用到，上升版本号可以用于重置数据
  table: {
    showHeader: true,
    keepSource: true,
    delayHover: 50,
    showOverflow: true,
    showHeaderOverflow: null,
    showFooterOverflow: null,
    highlightHoverRow: true,
    highlightCurrentRow: true,
    size: null,
    resizable: true,
    autoResize: true,
    syncResize: true,
    stripe: true,
    border: true,
    round: true,
    align: "center",
    radioConfig: {
      trigger: "default",
    },
    checkboxConfig: {
      strict: false,
      highlight: false,
      range: false,
      trigger: "default",
    },
    sortConfig: {
      remote: false,
      trigger: "default",
      orders: ["asc", "desc", null],
      sortMethod: null,
    },
    filterConfig: {
      remote: false,
      filterMethod: null,
    },
    expandConfig: {
      trigger: "default",
      showIcon: true,
      accordion: true,
      // trigger : "row"
    },
    treeConfig: {
      children: "children",
      hasChild: "hasChild",
      indent: 20,
      showIcon: true,
    },
    tooltipConfig: {
      theme: "dark",
      enterable: false,
    },
    editConfig: {
      trigger: "dblclick",
      mode: "row",
      showIcon: false,
      showStatus: true,
      autoClear: false, // 是否显示列编辑图标
      showAsterisk: true,
    },
    menuConfig: {
      header: {
        options: [
          [
            { code: 'refresh', name: '刷新' },
            { code: 'exportData', name: '导出.csv' },
            // { code: 'insert', name: '新增', record: { name: '默认名称' } }
          ]
        ]
      },
      body: {
        options: [
          [
            { code: 'refresh', name: '刷新' },
            { code: 'exportData', name: '导出.csv' },
            // { code: 'insert', name: '新增', record: { name: '默认名称' } }
          ]
        ]
      },
      visibleMethod: ({ type, options, columns }) => {
        let route = Router.currentRoute
        let permissButtons = Tools.getBtnPermiss(route);
        options[0].map(menu => {
          let node = XEUtils.filterTree(permissButtons, item => item.code === `${route.name}.${menu.code}`)[0];
          let arrays = ["refresh","exportData"]
          if (arrays.includes(menu.code) || node) {
            menu.visible = true;
          } else {
            menu.visible = false;
          }
        });
        return true
      }
    },
    importConfig: {
      // types: ['xls', 'xlsx', 'csv'],
      modes: ["insert", "covering"],
    },
    exportConfig: {
      useStyle: true,
      isColgroup: true,
      modes: ["current", "selected", "all"],
      columnFilterMethod: ({ column }) => {
        if (!column.property) {
          return false;
        }
        return true;
      },
    },
    customConfig: {
      storage: true,
      checkMethod: ({ column }) => {
        if (!column.property || column.property == "oper") {
          return false;
        }
        return true;
      },
    },
    scrollX: {
      gt: 60,
    },
    scrollY: {
      gt: 100,
    },
  },
  grid: {
    size: null,
    sortable: true,
    zoomConfig: {
      escRestore: true,
    },
    pagerConfig: {
      size: null,
      autoHidden: false,
      border: true,
      perfect: true,
      layouts: [
        "PrevJump",
        "PrevPage",
        "Number",
        "NextPage",
        "NextJump",
        "Sizes",
        "FullJump",
        "Total",
      ],
      pageSizes: [10, 30, 50, 100, 200, 500, 1000],
    },
    toolbarConfig: {
      perfect: false,
      import: false,
      export: false,
      print: false,
      zoom: true,
      custom: true,
    },
    sortConfig: {
      multiple: true,
    },
    // proxyConfig: {
    //   autoLoad: true,
    //   message: true,
    //   props: {
    //     list: null, // 用于列表，读取响应数据
    //     result: 'result', // 用于分页，读取响应数据
    //     total: 'page.total' // 用于分页，读取总条数
    //   },
    //   beforeItem: null,
    //   beforeColumn: null,
    //   beforeQuery: null,
    //   afterQuery: null,
    //   beforeDelete: null,
    //   afterDelete: null,
    //   beforeSave: null,
    //   afterSave: null
    // }
  },
  pager: {
    size: null,
    autoHidden: false,
    border: true,
    perfect: true,
    pageSize: 10,
    currentPage: 1,
    total: 0,
    layouts: [
      "PrevJump",
      "PrevPage",
      "Number",
      "NextPage",
      "NextJump",
      "Sizes",
      "FullJump",
      "Total",
    ],
    pageSizes: [10, 30, 50, 100, 200, 500, 1000],
  },
  form: {
    titleAlign: "right",
    titleColon: true,
    preventSubmit: false,
    validConfig: {
      autoPos: true,
    },
    size: null,
    colon: false,
    titleAsterisk: true,
  },
  input: {
    size: null,
    clearable: true,
    transfer: true,
    parseFormat: "yyyy-MM-dd HH:mm:ss.SSS",
    labelFormat: "yyyy-MM-dd",
    valueFormat: "yyyy-MM-dd HH:mm:ss",
    startWeek: 1,
    digits: 2,
    controls: true,
  },
  textarea: {
    // resize : "none",
    size: null,
    autosize: {
      minRows: 1,
      maxRows: 10,
    },
  },
  select: {
    size: null,
    transfer: true,
    multiCharOverflow: 8,
  },
  toolbar: {
    size: null,
    refresh: {
      icon: "fa fa-refresh",
      iconLoading: "fa fa-spinner fa-spin",
    },
    import: {
      mode: "covering",
      types: ["csv"],
      icon: "fa fa-upload",
    },
    export: {
      // types: ['csv', 'html', 'xml', 'txt'],
      type: 'xlsx',
      types: ['xls', 'xlsx', 'csv'],
      icon: "fa fa-download",
      filename: "自定义文件名",
    },
    zoom: {
      iconIn: "fa fa-arrows-alt",
      iconOut: "fa fa-expand",
    },
    custom: {
      isFooter: true,
      icon: "fa fa-cog",
    },
    buttons: [],
  },
  button: {
    size: null,
    transfer: false,
  },
  radio: {
    size: null,
  },
  checkbox: {
    size: null,
  },
  switch: {
    size: null,
  },
  modal: {
    // size: null,
    resize: true,
    minWidth: 300,
    minHeight: 200,
    lockView: true,
    mask: true,
    destroyOnClose: true,
    dblclickZoom: true,
    // duration: 3000,
    escClosable: true,
    transfer: true,
    // marginSize: 0,
    // showTitleOverflow: true,
    // storage: false
  },
  // list: {
  //   scrollY: {
  //     gt: 100
  //   }
  // }
});


VXETable.menus.add('exportData', (params, event) => {
  let { $table } = params
  $table.exportData()
})

VXETable.menus.add('insert', (params, event) => {
  let { $table, menu } = params
  // 读取自定义的参数
  $table.insert(menu.record)
})

// 自定义全局的格式化处理函数
VXETable.formats.mixin({
  // 格式化性别
  formatSex({ cellValue }, list) {
    // return cellValue ? (cellValue === '1' ? '男' : '女') : '';
    const item = list.find(item => item.value === cellValue)
    return item ? item.label : ''
  },
  // 格式化下拉选项
  formatSelect({ cellValue }, list) {
    const item = list.find(item => item.value === cellValue)
    return item ? item.label : ''
  },
  // 格式化日期，默认 yyyy-MM-dd HH:mm:ss
  formatDate({
    cellValue
  }, format) {
    return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd HH:mm:ss')
  },
  // 四舍五入金额，每隔3位逗号分隔，默认2位数
  formatAmount({ cellValue }, digits = 2) {
    return XEUtils.commafy(XEUtils.toNumber(cellValue), {
      digits
    })
  },
  // 格式化银行卡，默认每4位空格隔开
  formatBankcard({ cellValue }) {
    return XEUtils.commafy(XEUtils.toString(cellValue), {
      spaceNumber: 4,
      separator: ' '
    })
  },
  // 四舍五入,默认两位数
  formatFixedNumber({
    cellValue
  }, digits = 2) {
    return XEUtils.toFixed(XEUtils.round(cellValue, digits), digits)
  },
  // 向下舍入,默认两位数
  formatCutNumber({ cellValue }, digits = 2) {
    return XEUtils.toFixed(XEUtils.floor(cellValue, digits), digits)
  },
  // 转换 moment 类型为字符串
  toMomentString({ cellValue }, format) {
    return cellValue ? cellValue.format(format) : ''
  }
});


import FilterInput from '@/components/Filter/FilterInput.vue'
import FilterContent from '@/components/Filter/FilterContent.vue'
import FilterComplex from '@/components/Filter/FilterComplex.vue'
import FilterExcel from '@/components/Filter/FilterExcel.vue'

Vue.component(FilterInput.name, FilterInput)
Vue.component(FilterContent.name, FilterContent)
Vue.component(FilterComplex.name, FilterComplex)
Vue.component(FilterExcel.name, FilterExcel)

// 创建一个输入框筛选
VXETable.renderer.add('FilterInput', {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [<filterInput params={params}></filterInput>];
  },
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = ''
    })
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = XEUtils.get(row, column.property)
    if (cellValue) {
      return cellValue.indexOf(data) > -1
    }
    return false
  }
});

// 自定义表格操作 组件
import VxeGridButtons from '@/components/VxeTable/VxeGridButtons.vue'
Vue.component(VxeGridButtons.name, VxeGridButtons);
// 自定义树形表格操作按钮
VXETable.renderer.add("$VxeGridButtons", {
  renderItemContent(h, renderOpts, params) {
    return [<VxeGridButtons renderOpts={renderOpts} params={params} />];
  },
  // 编辑模式
  renderEdit(h, renderOpts, params) {
    params.isActiveByRow = true;
    return [<VxeGridButtons renderOpts={renderOpts} params={params} />];
  },
  // 展示模式
  renderCell(h, renderOpts, params) {
    params.isActiveByRow = false;
    return [<VxeGridButtons renderOpts={renderOpts} params={params} />];
  },
})


// 自定义封装组件自动化注册---表单组件
const VxeFormFiles = require.context("@/components/VxeForm", false, /.vue$/)
VxeFormFiles.keys().forEach(key => {
  const Template = VxeFormFiles(key).default || VxeFormFiles(key)
  Vue.component(Template.name, Template);
  VXETable.renderer.add('$' + Template.name, {
    // 表单模式
    renderItemContent(h, renderOpts, params) {
      return [<Template.name renderOpts={renderOpts} params={params} type={"renderItemContent"} />];
    },
    // 列表编辑模式
    renderEdit(h, renderOpts, params) {
      return [<Template.name renderOpts={renderOpts} params={params} type={"renderEdit"} />];
    },
    // 列表展示模式
    renderCell(h, renderOpts, params) {
      return [<Template.name renderOpts={renderOpts} params={params} type={"renderCell"} />];
    },
  })
})



// 自定义封装组件自动化注册--工具栏按钮
const ToolbarFiles = require.context("@/components/Toolbar", false, /.vue$/)
ToolbarFiles.keys().forEach(key => {
  const Template = ToolbarFiles(key).default || ToolbarFiles(key)
  Vue.component(Template.name, Template);
  VXETable.renderer.add('$' + Template.name, {
    renderToolbarButton(h, renderOpts, params) {
      return [<Template.name renderOpts={renderOpts} params={params} />];
    }
  })
})

export default VXETable;