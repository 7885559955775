<template>
<vxe-button :type="renderOpts.props.type" :status="renderOpts.props.status" :content="value" @click="clickEvent" class="button"></vxe-button>
</template>

<script>
export default {
  name: "VxeText",
  props: {
    type: String,
    renderOpts: {
      type: Object,
      default: () => {
        return {}
      }
    },
    params: Object,
  },
  computed: {
    value() {
      let { row, column } = this.params;
      return `${row[column.property]}`
    }
  },
  methods: {
    clickEvent() {
      let { row } = this.params;
      if (this.renderOpts && this.renderOpts.events) {
        this.renderOpts.events.click(row);
      }
    }
  }
}
</script>

<style>
.button {
  text-decoration: underline !important;
}
</style>
