<template>
<div class="wrapper">
  <div class="code_editor" v-if="type != 'renderCell'">
    <codemirror v-model="value" :options="options"></codemirror>
  </div>
  <span v-else>
    {{ options }}
  </span>
</div>
</template>

<script>
// 引入全局实例
import { codemirror } from 'vue-codemirror'
// 核心样式
import 'codemirror/lib/codemirror.css'
// 引入主题后还需要在 options 中指定主题才会生效
import 'codemirror/theme/darcula.css'
// 需要引入具体的语法高亮库才会有对应的语法高亮效果
// codemirror 官方其实支持通过 /addon/mode/loadmode.js 和 /mode/meta.js 来实现动态加载对应语法高亮库
// 但 vue 貌似没有无法在实例初始化后再动态加载对应 JS ，所以此处才把对应的 JS 提前引入
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/clike/clike.js'
import 'codemirror/mode/markdown/markdown.js'
import 'codemirror/mode/python/python.js'
import 'codemirror/mode/r/r.js'
import 'codemirror/mode/shell/shell.js'
import 'codemirror/mode/sql/sql.js'
import 'codemirror/mode/swift/swift.js'
import 'codemirror/mode/vue/vue.js'

// 尝试获取全局实例
export default {
  name: "CodeEditor",
  props: {
    params: Object,
    type: String,
  },
  components: {
    codemirror
  },
  data() {
    return {
      // 内部真实的内容
      code: '',
      // 默认的语法类型
      mode: 'javascript',
      // 编辑器实例
      coder: null,
      // 默认配置
      options: {
        // 缩进格式
        tabSize: 2,
        // 主题，对应主题库 JS 需要提前引入
        theme: 'darcula',
        // 显示行号
        lineNumbers: true,
        line: true,
      },
    }
  },
  computed: {
    value: {
      get() {
        let { row, column, data, property } = this.params;
        let options = this.type === "renderItemContent" ? data[property] : row[column.property];
        //正则匹配去除转义符
        let str = options.replace(/[\\]/g, '');
        //转成json对象
        let obj = JSON.parse(str.substring(1, str.length - 1))
        return JSON.stringify(obj, { '*': '*', }, 2);
      },
      set(newValue) {

      }
    },
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  .code_editor {
    width: 100%;
    border: 1px solid #ccc;
    box-sizing: border-box;

    .CodeMirror {
      height: 500px;
    }
  }
}
</style>
