<template>
<div>
  <div v-if="type =='renderItemContent'">
    <vxe-input v-model="value" suffix-icon="fa fa-search" :placeholder="props.placeholder" @click="clickEvent" @clear="clearEvent">
    </vxe-input>
    <vxe-modal ref="xModal" title='选择路线' width="1000" height="600" show-zoom :mask="false">
      <template v-slot>
        <div class="container">
          <VxeBasicTable ref="xGrid" service="/wisdom/inspectionRoute" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
          </VxeBasicTable>
        </div>
      </template>
    </vxe-modal>
  </div>
  <div v-else>
    {{value}}
  </div>
</div>
</template>

<script>
export default {
  name: "VxeSelectInspectionRoute",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      ...this.renderOpts,
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "routeName",
            title: "路线名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入线路名称",
              },
            },
          },
          {
            field: "remark",
            title: "描述",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入描述",
              },
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 100,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                status: "primary",
                label: "选择",
                eventName: "customEvent",
                events: {
                  click: (row) => {
                    this.selectEvent(row);
                  }
                }
              }]
            },
          },
        ],
        // editConfig : {}
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 130,
        mask: false,
        items: [],
        // 新增功能字段初始化
        data: {
          status: "1",
          enabled: "1",
          ownerTypeCd: "1",
          sex: "1",
          partymembertype: "0",
          joinPartyTime: "",
          type: "1005",
          communityId: ''
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "routeName",
            title: '路线名称',
            itemRender: {
              name: "$input",
              props: {
                placeholder: '请输入路线名称',
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          phone: "",
          ownerTypeCd: "1",
          type: "1001",
          enabled: "1"
        },
      },
    }
  },
  computed: {
    value() {
      let { data, property, row, column } = this.params;
      if (this.type == "renderItemContent") {
        return data[property] || "";
      } else {
        return row[column.property] || "";
      }
    }
  },
  created() {
    let { data, property, row, column } = this.params;
    if (this.type == "renderItemContent") {
      this.formOptions.data["parentId"] = data.parentId;
      this.searchOptions.data["parentId"] = data.parentId;
    }
  },
  methods: {
    clickEvent() {
      this.$refs.xModal.open();
    },

    selectEvent(row) {
      this.$confirm("是否选择当前检修线路!", this.$t("confirmTitle")).then((type) => {
        if (type === "confirm") {
          this.$refs.xModal.close();
          if (this.events) {
            Object.keys(this.events).forEach((key, index) => {
              if (index == 0) this.events[key](this.params, row);
            });
          }
        }
      });
    },

    clearEvent() {
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, {});
        });
      }
    },

    resetEvent() {
      this.loading = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  box-sizing: border-box;
}
</style>
