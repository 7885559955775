<template>
<el-divider :content-position="renderOpts.props.position">{{renderOpts.props.title}}</el-divider>
</template>

<script>
export default {
  name: "VxeDivider",
  props: {
    renderOpts: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
}
</script>

<style>

</style>
