<template>
<div>
  <template v-if="params.isActiveByRow">
    <vxe-button type="text" status="primary" :content="$t('save')" @click="events('submitEvent')"></vxe-button>
    <vxe-button type="text" :content="$t('cancel')" @click="events('cancelEvent')"></vxe-button>
  </template>
  <template v-else>
    <vxe-button type="text" :status="btn.status" :content="$t(btn.label)" @click="events(btn.eventName,index)" v-for="(btn,index) in lists" :key="index"></vxe-button>
  </template>
</div>
</template>

<script>
export default {
  name: "VxeGridButtons",
  props: ["renderOpts", "params"],
  computed: {
    lists() {
      let btnLists = this.renderOpts.lists || [{
          name: "edit",
          status: "primary",
          label: "编辑",
          eventName: "queryEvent"
        },
        {
          name: "delete",
          status: "danger",
          label: "删除",
          eventName: "removeEvent"
        }
      ];
      let permissButtons = this.$Tools.getBtnPermiss(this.$route);
      let lists = [];
      let { row } = this.params;
      btnLists.forEach(item => {
        // 查找权限按钮
        let { params } = item;
        let node = this.$XEUtils.filterTree(permissButtons, btn => btn.code == `${this.$route.name}.${item.name}`)[0];
        if (node && !params || node && params && params.value.indexOf(row[params.property]) > -1 || item.eventName == "customEvent") {
          item.label = node ? node.name : item.label;
          lists.push(item)
        }
      });
      return lists;
    }
  },
  methods: {
    events(type, index) {
      // 暂时找不到好的方法
      let xGrid = this.params.$table.$parent.$parent.$parent.$refs.xGrid;
      let item = this.lists[index];
      if (type == "removeEvent") {
        xGrid.removeEvent([this.params.row.id]);
      } else if (type && type != "customEvent" && xGrid[type]) {
        xGrid[type](this.params.row, item);
      } else if (item && item.events) {
        Object.keys(item.events).forEach((key, index) => {
          if (index == 0) item.events[key](this.params.row);
        });
      }
    }
  },
};
</script>
