<template>
<div class="picture">
  <div class="camera_outer">
    <video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay class="scaleX video"></video>
    <canvas style="display: none" id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
    <div v-if="imgSrc" class="img_bg_camera scaleX">
      <img :src="imgSrc" alt="" class="tx_img" />
    </div>
  </div>

  <div class="button_list">
    <el-button @click="getCompetence()">{{
        imgSrc ? "重新拍摄" : "打开摄像头"
      }}</el-button>
    <el-button @click="setImage()" v-if="!imgSrc">拍照</el-button>
    <el-button @click="imgUpload()" v-else>上传</el-button>
  </div>
</div>
</template>

<script>
export default {
  name: "VxePicture",

  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },

  data() {
    return {
      action: `/sys/sysFile`,
      videoWidth: 300,
      videoHeight: 300,
      imgSrc: "",
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      file: "",
      ...this.renderOpts,
    };
  },

  created() {
    let { row, column, data, property } = this.params;
    let value =
      this.type == "renderItemContent" ? data[property] : row[column.property];
    if (value) {
      this.imgSrc = `${this.action}/getFile/${value}`;
    }
  },

  methods: {
    // 调用权限（打开摄像头功能）
    getCompetence() {
      this.imgSrc = "";
      let _this = this;
      this.thisCancas = document.getElementById("canvasCamera");
      this.thisContext = this.thisCancas.getContext("2d");
      this.thisVideo = document.getElementById("videoCamera");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)

          let getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;

          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致

          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }

          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      let constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: "scaleX(-1)",
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }

          _this.thisVideo.onloadedmetadata = function (e) {
            _this.thisVideo.play();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 绘制图片（拍照功能）
    setImage() {
      // 点击，canvas画图
      this.thisContext.drawImage(
        this.thisVideo,
        0,
        0,
        this.videoWidth,
        this.videoHeight
      );

      // 获取图片base64链接
      let image = this.thisCancas.toDataURL("image/png");
      this.imgSrc = image;
      this.$emit("refreshDataList", this.imgSrc);
      this.stopNavigator();
      this.file = this.dataURLtoFile(image, "image.png");
    },

    // base64转文件

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },

    // 关闭摄像头
    stopNavigator() {
      this.thisVideo.srcObject.getTracks()[0].stop();
    },

    // 上传图片
    imgUpload() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.Axios
        .post(this.action, formData, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((res) => {
          let { code, data, message } = res.data;
          if (code == 200) {
            // let { row, column, data, property } = this.params;
            // if (this.type == "renderItemContent") {
            //   console.log(data, property, this.renderOpts)
            //   this.$set(data, property, res.data.data);
            // } else {
            //   this.$set(row, column.property, res.data.data);
            // }
            if (this.events) {
              Object.keys(this.events).forEach((key, index) => {
                if (index == 0) this.events[key](this.params, data);
              });
            }
            this.$message.success("上传成功");
          } else {
            this.$message.error(message);
          }

        });
    },
  },
};
</script>

<style lang="scss" scoped>
.picture {
  position: relative;
  width: 300px;
  background-color: #fff;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
}

.scaleX {
  transform: scaleX(-1);
}

.video {
  width: 300px;
  height: 300px;
  // border-radius: 50%;
  overflow: hidden;
  background-color: #000;
}

.img_bg_camera {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}

.button_list {
  display: flex;
  justify-content: space-around;
}
</style>
