<template>
  <vxe-input v-model="value" :placeholder="props.placeholder" maxlength="18" :disabled="props.disabled" :controls="false"
    @input="inputEvent"></vxe-input>
</template>

<script>
export default {
  name: "VxeIDCardInput",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      value: "",
      ...this.renderOpts,
    };
  },
  created() {
    let { row, column, data, property } = this.params;
    this.value =
      this.type == "renderItemContent" ? data[property] : row[column.property];
    this.setItemRules(this.value);
  },
  methods: {
    setItemRules(value) {
      let { $form, data, item, property } = this.params;
      this.type == "renderItemContent" ? data[property] = value : row[column.property] = value;
      let itemRules = [
        { required: true, message: "请输入有效的18位身份证编号" },
        {
          validator: ({ itemValue }) => {
            if (itemValue && !this.$Tools.regs.IDCard.test(itemValue)) {
              return new Error(
                `${this.params.item.title}格式不正确，请重新输入!`
              );
            }
          },
        },
      ];
      if (value || (item.itemRender.props && item.itemRender.props.required)) {
        $form.rules[property] = itemRules;
      } else {
        $form.rules[property] = [];
      }

      console.log($form.rules);
    },
    inputEvent({ value }) {
      this.setItemRules(value);
    },
  },
};
</script>
