<template>
<div>
  <div class="upload">
    <el-upload ref="upload" :action="action" :file-list="fileList" :multiple="false" :show-file-list="false" :headers="headers" name="file" :limit="limitCount" :on-error="onError" :on-success="onSuccess" :disabled="renderOpts.props && renderOpts.props.disabled">
      <vxe-button status="primary" content="上传附件"></vxe-button>
      <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
    </el-upload>
  </div>
</div>
</template>

<script>
export default {
  name: "VxeUploadBtn",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
    limitCount: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
  data() {
    return {
      action: `/sys/sysFile`,
      headers: {
        Authorization: localStorage.token,
      },
      fileList: [],
      ...this.renderOpts,
    };
  },

  created() {
    let { row, column, data, property } = this.params;
    let value = "";
    if (this.type == "renderItemContent") {
      value = this.$Tools.getValueByfieldEvent(data, property);
    } else {
      value = row[column.property];
    }
    if (value) {
      this.fileList = [{
        name: value,
        url: `${this.action}/getFile/${value}`,
      }];
    }
  },

  methods: {
    beforeUpload() {},
    handleRemove(file) {
      this.fileList.forEach((img, index) => {
        if (file.url == img.url) {
          this.fileList.splice(index, 1);
        }
      });
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, "");
        });
      }
    },

    onSuccess({ data }) {
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, data);
        });
      }
      this.$message.success("上传成功");
    },
    onError(err) {
      console.log(err);
    },
  },

};
</script>

<style lang="scss">
.uploadBtn {
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}
</style>
