<template>
<div>
  <vxe-input v-model="value" suffix-icon="fa fa-search" :placeholder="props.placeholder" @click="clickEvent" @clear="clearEvent"></vxe-input>
  <vxe-modal ref="xModal" title="选择房间号" width="1000" height="600" show-zoom :mask="false">
    <template v-slot>
      <div class="container">
        <VxeBasicTable ref="xGrid" service="/communitys/tBuildingRoom" :gridOptions="gridOptions" :searchOptions="searchOptions">
        </VxeBasicTable>
      </div>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "VxeSelectRoom",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      value: "",
      ...this.renderOpts,
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            field: "name",
            title: "房屋编号",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房屋编号",
              },
            },
          },
          {
            field: "ownerName",
            title: this.$t("currentOwner"),
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                placeholder: this.$t("currentPlaceholder"),
              }
            },
          },
          {
            field: "ownerLink",
            title: "联系电话",
            minWidth: 120,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入联系电话",
              }
            },
          },
          {
            field: "roomType",
            title: "房屋类型",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "houseType",
              props: {
                placeholder: "请选择房屋类型",
              },
            },
          },
          {
            field: "section",
            title: "房屋户型",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "section",
              props: {
                placeholder: "请选择房屋户型",
              },
            },
          },
          {
            field: "roomBuiltUpArea",
            title: "建筑面积",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                min: 0,
                max: 99999,
                maxlength: 24,
                placeholder: "请输入建筑面积",
              },
            },
          },
          {
            field: "feeCoefficient",
            title: "算费系数",
            minWidth: 80,
            editRender: {
              name: "$input",
              props: {
                type: "float",
                maxlength: 24,
                placeholder: "请输入算费系数",
              }
            },
          },
          {
            field: "status",
            title: "房屋状态",
            width: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "houseStatus",
              props: {
                placeholder: "请选择房屋状态",
              }
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 100,
            cellRender: {
              name: "$button",
              props: {
                content: "选择",
                type: "text",
                status: "primary",
              },
              events: {
                click: ({ row }) => {
                  this.selectEvent(row);
                }
              }
            },
          },
        ],
        editConfig: {
          enabled: false
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "selectType",
            resetValue: "1",
            itemRender: {
              name: "$select",
              optionTypeCode: "searchTypes",
              props: {
                placeholder: "房间编号",
              },
            },
          },
          {
            field: "name",
            visibleMethod({ data, property }) {
              if (data.selectType == 1) {
                return true
              } else {
                data[property] = "";
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入房间编号"
              },
            },
          },
          {
            field: "ownerName",
            visibleMethod({ data, property }) {
              if (data.selectType == 2) {
                return true
              } else {
                data[property] = "";
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: this.$t("ownerNamePlaceholder")
              },
            },
          },
          {
            field: "ownerLink",
            visibleMethod({ data, property }) {
              if (data.selectType == 3) {
                return true
              } else {
                data[property] = "";
                return false
              }
            },
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输手机号"
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          selectType: "1",
        },
      },
    }
  },

  created() {
    let { data, property } = this.params;
    if (data[property]) {
      this.value = data[property];
    }
  },

  methods: {
    ...mapActions([
      "findByEventService",
    ]),

    clickEvent() {
      let { data, property } = this.params;
      this.searchOptions.data["parentType"] = "1001";
      this.searchOptions.data["parentId"] = data.parentId;
      this.searchOptions.data["status"] = data.roomStatus;
      this.value = data[property];
      this.$refs.xModal.open();
    },

    selectEvent(row) {
      this.$confirm("是否选择当前房屋!", this.$t("confirmTitle")).then((type) => {
        if (type === "confirm") {
          this.value = row.name;
          this.$refs.xModal.close();
          if (this.events) {
            Object.keys(this.events).forEach((key, index) => {
              if (index == 0) this.events[key](this.params, row);
            });
          }
        }
      });
    },

    clearEvent() {
      let { data, property } = this.params;
      data[property] = "";
      data["ownerName"] = "";
      data["ownerLink"] = "";
      data["checkInDate"] = "";
      data["status"] = "1";
    },

    resetEvent() {
      this.loading = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  box-sizing: border-box;
}
</style>
