// 获取当前环境
import XEUtils from "xe-utils";
import Logos from "../../../LogoConfig";

// 西房特殊功能
let node = XEUtils.filterTree(Logos, item => item.origin === location.origin)[0] || {};
let owner = node.code == "xf" ? "租户" : "业主";
let familyMember = node.code == "xf" ? "同住人" : "家庭成员";

export default {
    wellCome: "欢迎",
    confirmTitle: "温馨提示",
    createAndSave: "新增&保存",
    editAndSave: "编辑&保存",
    save: "保存",
    cancel: "取消",
    isDelete: "确定是否删除该数据?",
    pleaseSelectDataToDelete: "请选择您要删除的数据",
    deleteSuccess: "删除成功",

    // 业主模块
    ownerInfo: `${owner}信息`,
    ownerName: `${owner}姓名`,
    ownerNamePlaceholder: `请输入${owner}姓名`,
    currentOwner: `所属${owner}`,
    currentPlaceholder: `请选择所属${owner}`,
    selectOwner: `选择${owner}`,
    ownerType: `${owner}类型`,
    ownerTypePlaceholder: `请选择${owner}类型`,
    familyMember: familyMember,
    familyMemberNum: `${familyMember}数`,
    familyMemberNumPlaceholder: `请输入${familyMember}数`,
};