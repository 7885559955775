<template>
<div class="tree">
  <span>
    <vxe-input v-model="filterText" :placeholder="placeholder"></vxe-input>
  </span>
  <el-tree ref="tree" :data="treeData" accordion :filter-node-method="filterNode" node-key="id" :highlight-current="true" :default-expand-all="expandAll" :expand-on-click-node="false" :props="defaultProps" @node-click="handleNodeClick" v-loading="loading" class="elTree" :current-node-key="currentKey">
    <div class="treeNode" :title="node.label" slot-scope="{ node }">
      <!-- <i v-if="node.data.type == '2'" class="iconfont icon-zuzhijigou1"></i> -->
      <i v-if="node.data.type == '1001'" class="iconfont icon-xiaoqu2"></i>
      <i v-if="node.data.type == '1004'" class="iconfont icon-fangwu1" :style="styleMap(node.data.status)"></i>
      <i v-if="node.data.type == '2002'" class="iconfont icon-zhihuitingche"></i>
      {{ node.label }}
    </div>
  </el-tree>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    service: String, // 接口地址
    placeholder: {
      type: String,
      default: () => {
        return "请输入关键字";
      },
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "children",
          label: "name",
        };
      },
    },
    params: {
      type: Object,
      default: () => {
        return {};
      },
    },
    expandAll: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      loading: false,
      treeData: [],
      filterText: "",
      currentKey: null
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.getLists();
  },
  methods: {
    ...mapActions(["getTreeService"]),
    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({
        service: this.service,
        params: this.params || {},
      }).then(({ code, data, message }) => {
        if (code == 200) {
          let arrs = ["/sys/sysDictType"]
          this.treeData = arrs.indexOf(this.service) > -1 ? data : this.$XEUtils.toArrayTree(data);
          if (this.treeData[0]) {
            this.handleNodeClick(this.treeData[0])
          }
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    handleNodeClick(data) {
      this.currentKey = data.id
      this.$parent.handleNodeClick(this.treeData, data);
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(data.id)
      })
    },

    resetEvent() {
      this.loading = false;
    },

    styleMap(status) {
      let mapObj = {
        '0': '#000',
        '1': '#ccc',
        '2': '#122d78',
        '3': '#26d116',
        '4': '#0cb5d3',
        '5': '#e07918',
      }
      if (status || status == '0') {
        return 'color:' + mapObj[status]
      } else {
        'color: red'
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tree {
  padding-top: 10px;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  span {
    padding-bottom: 5px;
  }

  .treeNode {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;

    .iconfont {
      font-size: 14px;
      // margin-right: 3px;
    }
  }

  .vxe-input {
    width: 100%;
  }
}
</style>
