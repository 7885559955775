import axios from "axios";
import Router from "@/router";
import XEUtils from "xe-utils";
import { Message } from "element-ui";

let Axios = axios.create({
    // baseURL: process.env.VUE_APP_BASEURL,
    // baseURL: "https://mbl.xatali.com",
    // baseURL: "https://wysq.yidiwuyou.com.cn",
    // baseURL: "https://znwy.snznkc.com",
    timeout: 300000,
    headers: {
        "Content-Type": "application/json;charset=UTF-8", //设置跨域头部
    },
});

// console.log(process.env);
Axios.defaults.withCredentials = true;
// 添加请求拦截器
Axios.interceptors.request.use(
    function(config) {
        const Timestamp = new Date().getTime();
        if (config.url.indexOf("?") > -1) {
            config.url = `${config.url}&${Timestamp}`;
        } else {
            config.url = `${config.url}?${Timestamp}`;
        }
        // 在发送请求之前做些什么
        if (config.data && !XEUtils.isFormData(config.data)) {
            config.data = JSON.stringify(config.data);
        }
        config.headers["language"] = localStorage.locale;
        let token = localStorage.token;
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    function(error) {
        console.log(error);
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);
// 添加响应拦截器
Axios.interceptors.response.use(
    function({ data }) {
        // 统一拦截器
        if (data.code == 401 || data.code == -402 || data.code == 403) {
            Router.replace({
                name: "Login",
            });
        } else if (data.code == 200 && !data.success) {
            // 对响应数据做点什么
            Message({
                message: data.message,
                type: "error",
            });
            return new Error();
        } else if (data.code == 500) {
            // 对响应数据做点什么
            Message({
                message: data.message,
                type: "error",
                duration: "8000"
            });
            return new Error();
        } else if (data.code == 200 && data.success) {
            return data;
        } else {
            return new Error();
        }
    },
    function(error) {
        let { response } = error;
        if (response.status == 400) {
            Message({
                message: response.data.message,
                type: "error",
            });
        } else if (response.status == 500) {
            Message({
                message: "服务器内部异常，请联系管理员!",
                type: "error",
            });
        }
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

export default Axios;