import XEUtils from "xe-utils";
//项目中公共方法
const regs = {
    mail: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
    url: /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i,
    phone: /^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/,
    telephone: /^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/,
    number: /^[0-9]*$/,
    IDCard: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    decimals: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
    symbol: /^[0-9a-zA-Z\u0000-\u00FF]+$/, //半角符号
};

// 获取按钮权限
const getBtnPermiss = function(route) {
    let Resources = localStorage.resources ?
        JSON.parse(localStorage.resources) : [];
    let resources = XEUtils.filterTree(
        Resources,
        (item) => item.code == route.name
    );
    return resources && resources.length ? resources[0].children : [];
};

const serializeQueryUrl = function(objData) {
    let querySrt =
        JSON.stringify(objData) != "{}" ? `${JSON.stringify(objData)}&` : "";
    return encodeURI(querySrt);
};

const creaElementA = function(url) {
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// blob转文件并下载
const downloadFileByBlob = function(file, fileName) {
    let blob = new Blob([file], { type: fileName.split(".")[0] })
    let blobUrl = window.URL.createObjectURL(blob)
    let link = document.createElement('a')
    link.download = fileName
    link.style.display = 'none'
    link.href = blobUrl
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

// 将数据字典和数据进行整合并渲染
const buildDataByDicts = function(columns, dicts, defaultProps) {
    if (!dicts || !columns) return [];
    columns.map((item, index) => {
        const render = item.editRender || item.itemRender;
        if (render && render.optionTypeCode) {
            render["options"] = treeDataEvent(
                dicts[render.optionTypeCode],
                defaultProps
            );
        }
        if (item.children && item.children.length) {
            buildDataByDicts(item.children, dicts, defaultProps);
        }
    });
    return columns || [];
};

// 将数据字典和数据进行整合并渲染
const buildDataToGrid = function(columns, data, property, defaultProps) {
    // 设置默认渲染的字段
    if (!defaultProps) defaultProps = { value: "id", label: "name" };
    if (!data || !columns) return [];
    columns.map((item, index) => {
        if (item.field == property) {
            const render = item.editRender || item.itemRender;
            render["options"] = treeDataEvent(data, defaultProps);
        }
        if (item.children && item.children.length) {
            buildDataToGrid(item.children, data, property, defaultProps);
        }
    });
    return columns || [];
};

// 循环组装树形结构的数据
const treeDataEvent = function(lists, props) {
    if (!lists) return [];
    lists.map((item) => {
        item.value = item[props.value];
        item.label = item[props.label];
        if (item.children && item.children.length) {
            treeDataEvent(item.children, props);
        } else {
            item.children = null;
        }
    });
    return lists || [];
};

// 根据VALUE/ID查找树形结构的文本
const getNodeById = function(lists, id, property = "value") {
    if (!lists || !lists.length) {
        return;
    }
    let temp = null;
    lists.forEach((item) => {
        if (!temp && item[property] == id) {
            temp = item;
        }
        if (!temp && item.children && item.children.length) {
            temp = getNodeById(item.children, id, property);
        }
    });
    if (temp) {
        return temp;
    }
};

// 对于vxe-table中的特殊字段禁用或启用
const enabledEvent = function(lists, propertys, bool) {
    propertys.forEach((property) => {
        lists.map((item) => {
            if (item.field == property && item.itemRender.props) {
                item.itemRender.props["disabled"] = bool;
            }
        });
    });
};
const convertCurrency = (money) => {
    //汉字的数字
    var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
    );
    //基本单位
    var cnIntRadice = new Array("", "拾", "佰", "仟");
    //对应整数部分扩展单位
    var cnIntUnits = new Array("", "万", "亿", "兆");
    //对应小数部分单位
    var cnDecUnits = new Array("角", "分", "毫", "厘");
    //整数金额时后面跟的字符
    var cnInteger = "整";
    //整型完以后的单位
    var cnIntLast = "元";
    //最大处理的数字
    var maxNum = 999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = "";
    //分离金额后用的数组，预定义
    var parts;
    if (money == "") {
        return "";
    }
    money = parseFloat(money);
    if (money >= maxNum) {
        //超出最大处理数字
        return "";
    }
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    //转换为字符串
    money = money.toString();
    if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
    } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1);
            var p = IntLen - i - 1;
            var q = p / 4;
            var m = p % 4;
            if (n == "0") {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                //归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != "") {
        let decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            let n = decimalNum.substr(i, 1);
            if (n != "0") {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == "") {
        chineseStr += cnInteger;
    }
    return chineseStr;
};

// 处理表单中字段渲染的问题,如：aa.bb
const getValueByfieldEvent = function(data, property) {
    if (property.indexOf(".") > -1) {
        let obj = data;
        property = property.split(".");
        property.forEach((item) => {
            obj = obj[item];
        });
        if (obj) {
            return obj;
        }
    }
    return data[property];
};

const Tools = {
    regs,
    getBtnPermiss,
    serializeQueryUrl,
    creaElementA,
    downloadFileByBlob,
    buildDataToGrid,
    buildDataByDicts,
    treeDataEvent,
    getNodeById,
    enabledEvent,
    convertCurrency,
    getValueByfieldEvent,
};

export default Tools;