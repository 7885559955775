<template>
<div>
  <span v-if="type != 'renderCell'">
    <el-cascader v-model="currNode.value" :placeholder="placeholder" :show-all-levels="false" :options="tableData" filterable></el-cascader>
  </span>
  <span v-else>
    {{ currNode.label }}
  </span>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "VxeCascader",
  props: {
    renderOpts: {
      type: Object,
      default: () => {
        return {
          value: "value",
          label: "label",
        }
      }
    },
    params: Object,
    type: String,
  },
  data() {
    return {
      tableData: [],
    };
  },
  computed: {
    ...mapState(["dictionaries"]),
    currNode: {
      get() {
        let { row, column, data, property } = this.params;
        let nodeId = this.type == "renderItemContent" ? data[property] : row[column.property]
        return this.$Tools.getNodeById(this.tableData, nodeId) || {}
      },
      set(newValue) {
        let { row, column, data, property } = this.params;
        if (this.type == "renderItemContent") {
          this.$set(data, property, newValue[this.renderOpts.defaultProps.value])
        } else {
          this.$set(row, column.property, newValue[this.renderOpts.defaultProps.value])
        }
      }
    },
    placeholder() {
      return this.renderOpts.props && this.renderOpts.props.placeholder || "请选择"
    },
  },
  created() {
    this.getLists();
  },
  methods: {
    ...mapActions(['getTreeService']),
    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({ service: this.renderOpts.service }).then(({ code, data, message }) => {
          if (code == 200) {
            // 数据特殊处理将形参（name,id）,转换为固定结构(value,label),并将数据字段存储到缓存中
            this.tableData = this.$Tools.treeDataEvent(data, this.renderOpts.defaultProps);
          }
        });
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
