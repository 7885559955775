<template>
<div>
  <div v-for="(item,index) in list" :key="index" class="input_item">
    <vxe-button type="text" :content="$dictionary.serialNumber[index] + '：'"></vxe-button>
    <vxe-input v-model="list[index].label" :placeholder="`请输入选项${params.data.type == 3 ? $dictionary.serialNumber[index]:index+1}`" style="width:85%" @change="inputchange"></vxe-input>
    <vxe-button type="text" status="danger" content="删除" @click="removalEvent(index)"></vxe-button>
  </div>
  <vxe-button status="primary" content="新增选项" @click="addOptions"></vxe-button>
</div>
</template>

<script>
export default {
  name: 'VxeFormGeneration',
  props: {
    renderOpts: Object,
    params: Object,
    type: String
  },
  data() {
    return {
      ...this.renderOpts,
    }
  },
  computed: {
    list: {
      get() {
        let { data, property } = this.params;
        return data[property]
      },
      set(newValue) {
        let { data, property } = this.params;
        let mapLsit = newValue.map((item, index) => {
          item.value = index
          return item
        })
        this.$set(data, property, mapLsit);
      }
    },
  },

  methods: {
    addOptions() {
      if (this.list > 12) {
        this.$message.error('最多添加12个选项')
        return
      }
      if (!this.list[this.list.length - 1].label) {
        this.$message.error(`请输入选项${this.params.data.type == 3 ? this.$dictionary.serialNumber[this.list.length - 1]:this.list.length}的内容`)
        return
      }
      this.list.push({
        label: '',
        value: this.list.length
      })
      this.inputchange();
    },
    inputchange() {
      // let { data, property } = this.params;
      // let mapLsit = this.list.forEach((item, index) => {
      //   item.value = index
      //   return item
      // })
      // this.$set(data, property, mapLsit);
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, this.list);
        });
      }
    },
    removalEvent(index) {
      this.list.splice(index, 1);
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, this.list);
        });
      }
    }
  },
  watch: {
    'params.data.type': {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          if (newVal == 2 || newVal == 3) {
            this.list = [{
              label: '',
              value: '1'
            }]
          } else {
            this.list = []
          }
        }
      },
      deep: true,
      immediate: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.input_item {
  display: flex;
  margin: 15px 0;
  line-height: 34px;
}
</style>
