<template>
<div>
  <div v-if="type =='renderItemContent'">
    <vxe-input v-model="value" suffix-icon="fa fa-search" :placeholder="props.placeholder" @click="clickEvent" @clear="clearEvent">
    </vxe-input>
    <vxe-modal ref="xModal" title='选择合同甲方' width="1000" height="800" show-zoom :mask="false">
      <template v-slot>
        <div class="container">
          <VxeBasicTable ref="xGrid" service="/wisdom/tContractPartyA" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
          </VxeBasicTable>
        </div>
      </template>
    </vxe-modal>
  </div>
  <div v-else>
    {{value}}
  </div>
</div>
</template>

<script>
export default {
  name: "VxeSelectContractOwner",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      ...this.renderOpts,
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "partyAName",
            title: "甲方名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入甲方名称",
              },
            },
          },
          {
            field: "name",
            title: "甲方联系人",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入甲方联系人",
              },
            },
          },
          {
            field: "phone",
            title: "甲方联系电话",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 100,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                status: "primary",
                label: "选择",
                eventName: "customEvent",
                events: {
                  click: (row) => {
                    this.selectEvent(row);
                  }
                }
              }]
            },
          },
        ],
        // editConfig : {}
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 130,
        mask: false,
        items: [{
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "基本信息",
                position: "left"
              }
            },
          },
          {
            field: "communityId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              service: "/sys/sysOrganization/treeQueryAuthCommon/communtiy",
              defaultProps: {
                label: "name",
                value: "id",
              },
              expandAll: true,
              props: {
                disabled: true,
                placeholder: "请选择所属小区",
              },
              events: {
                change: ({ data }) => {
                  if (data.type == '1001') {
                    this.$refs.xGrid.formData["communityId"] = data.communityId;
                  } else {
                    this.$message.error('请选择所属小区')
                  }
                }
              }
            },
          },
          {
            field: "name",
            title: this.$t("ownerName"),
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: this.$t("ownerNamePlaceholder"),
              },
            },
          },
          {
            field: "phone",
            title: "联系电话",
            span: 12,
            itemRender: {
              name: "$VxePhoneInput",
              props: {
                required: true,
                placeholder: "请输入手机号",
              },
            },
          },
          {
            field: "idCard",
            title: "身份证号",
            span: 12,
            itemRender: {
              name: "$VxeIDCardInput",
              props: {
                placeholder: "请输入身份证号",
              },
            },
          },
          {
            field: "sex",
            title: "性别",
            span: 12,
            itemRender: {
              name: '$select',
              optionTypeCode: "sex",
              props: {
                placeholder: "请选择性别",
              },
            },
          },
          {
            field: "age",
            title: "年龄",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: 'number',
                min: 0,
                max: 120,
                placeholder: "请输入年龄",
              },
            },
          },
          {
            field: "nation",
            title: "民族",
            span: 12,
            itemRender: {
              name: '$input',
              props: {
                maxlength: 10,
                placeholder: "请输入民族",
              },
            },
          },
          {
            field: "physicalCondition",
            title: "身体状况",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 10,
                placeholder: "请输入身体状况",
              },
            },
          },
          {
            field: "familyNum",
            title: this.$t("familyMemberNum"),
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: 'number',
                min: 0,
                max: 10,
                placeholder: this.$t("familyMemberNumPlaceholder"),
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "党员信息",
                position: "left"
              }
            },
          },
          {
            field: "partymembertype",
            title: "党员类型",
            span: 12,
            itemRender: {
              name: '$select',
              optionTypeCode: "partyMemberType",
              props: {
                placeholder: "请选择党员类型",
              },
              events: {
                change: ({ data, property }) => {
                  if (data[property] == "0") {
                    data["joinPartyTime"] = ""
                    data["partyPosts"] = ""
                  }
                }
              }
            },
          },
          {
            field: "joinPartyTime",
            title: "入党日期",
            span: 12,
            visibleMethod({ data }) {
              if (data.partymembertype == 0) {
                return false
              } else {
                return true
              }
            },
            itemRender: {
              name: "$input",
              props: {
                type: "date",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择入党日期",
              },
            },
          },
          {
            field: "partyPosts",
            title: "党内职务",
            visibleMethod({ data }) {
              if (data.partymembertype == 0) {
                return false
              } else {
                return true
              }
            },
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入党内职务",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          communityId: [
            { required: true, message: "请选择所属小区" }
          ],
          name: [
            { required: true, message: this.$t("ownerNamePlaceholder") }
          ],
          link: [
            { required: true, message: "请输入手机号" }
          ],
          sex: [
            { required: true, message: "请选择性别" }
          ],
          ownerTypeCd: [
            { required: true, message: this.$t("ownerTypePlaceholder") }
          ],
          parentId: [{ required: true, message: "请选择所属上级" }],
        },
        // 新增功能字段初始化
        data: {
          status: "1",
          enabled: "1",
          ownerTypeCd: "1",
          sex: "1",
          partymembertype: "0",
          joinPartyTime: "",
          type: "1005",
          communityId: ''
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "partyAName",
            title: '甲方名称',
            itemRender: {
              name: "$input",
              props: {
                placeholder: '请输入甲方名称',
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          phone: "",
          ownerTypeCd: "1",
          type: "1001",
          enabled: "1"
        },
      },
    }
  },
  computed: {
    value() {
      let { data, property, row, column } = this.params;
      if (this.type == "renderItemContent") {
        return data[property] || "";
      } else {
        return row[column.property] || "";
      }
    }
  },
  created() {
    let { data, property, row, column } = this.params;
    if (this.type == "renderItemContent") {
      this.formOptions.data["parentId"] = data.parentId;
      this.searchOptions.data["parentId"] = data.parentId;
    }
  },
  methods: {
    clickEvent() {
      this.$refs.xModal.open();
    },

    selectEvent(row) {
      this.$confirm("是否选择当前甲方!", this.$t("confirmTitle")).then((type) => {
        if (type === "confirm") {
          this.$refs.xModal.close();
          if (this.events) {
            Object.keys(this.events).forEach((key, index) => {
              if (index == 0) this.events[key](this.params, row);
            });
          }
        }
      });
    },

    clearEvent() {
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, {});
        });
      }
    },

    resetEvent() {
      this.loading = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  box-sizing: border-box;
}
</style>
