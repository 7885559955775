<template>
<div>
  <span v-if="type != 'renderCell'">
    <vxe-input v-model="currNode.label" :placeholder="placeholder" @click="$refs.xModal.open()" @clear="clearEvent"></vxe-input>
    <vxe-modal ref="xModal" :title="placeholder" width="800" height="500" show-zoom>
      <template v-slot>
        <vxe-input v-model="filterText" placeholder="输入关键字进行过滤"></vxe-input>
        <el-tree ref="tree" class="filter-tree" :data="tableData" :props="renderOpts.defaultProps" default-expand-all :highlight-current="true" :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="getTreeNode">
        </el-tree>
      </template>
    </vxe-modal>
  </span>
  <span v-else>
    {{ currNode.label }}
  </span>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "VxeSelectModal",
  props: {
    renderOpts: {
      type: Object,
      default: () => {
        return {
          value: "code",
          label: "label",
        }
      }
    },
    params: Object,
    type: String,
  },
  data() {
    return {
      tableData: [],
      filterText: '',
      currNode: {},
    };
  },
  computed: {
    ...mapState(["dictionaries"]),
    placeholder() {
      return this.renderOpts.props && this.renderOpts.props.placeholder || "请选择"
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    this.getLists();
  },
  methods: {
    ...mapActions(['getTreeService']),
    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({
        service: this.renderOpts.service,
        params: {}
      }).then(({ code, data, message }) => {
        if (code == 200) {
          // 数据特殊处理将形参（name,id）,转换为固定结构(value,label),并将数据字段存储到缓存中;
          this.tableData = this.$Tools.treeDataEvent(data, this.renderOpts.defaultProps);
          let nodeId = this.type == "renderItemContent" ? this.params.data[this.params.property] : this.params.row[this.params.column.property];
          this.currNode = this.$Tools.getNodeById(this.tableData, nodeId) || {};
        }
      });
    },

    // 节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    // 获取节点
    getTreeNode(node) {
      if (this.type == "renderItemContent") {
        this.params.data[this.params.property] = node.id;
      } else {
        this.params.row[this.params.column.property] = node.id;
      }
      this.currNode = node;
      this.$refs.xModal && this.$refs.xModal.close();
    },

    clearEvent() {
      if (this.type == "renderItemContent") {
        this.params.data[this.params.property] = null;
      } else {
        this.params.row[this.params.column.property] = null;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
