<template>
<div>
  <div class="upload">
    <el-upload ref="upload" :action="action" :file-list="fileList" :multiple="false" :headers="headers" name="file" :limit="limitCount" :on-error="onError" :on-success="onSuccess" list-type="picture-card" class="uploadImg" :class="{ hide: hideUpload }" :disabled="renderOpts.props && renderOpts.props.disabled">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }" class="file">
        <el-image class="el-upload-list__item-thumbnail" :src="file.url" alt="" fit="fill" :preview-src-list="[file.url]" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <!-- <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
            <i class="el-icon-download"></i>
          </span> -->
          <span v-if="!renderOpts.props || renderOpts.props && !renderOpts.props.disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <div class="uploadBtn">
      <el-button type="primary" @click="openPhotoEvent" :disabled="renderOpts.props && renderOpts.props.disabled" v-if="logo.code == 'xf'">拍照上传</el-button>
    </div>
  </div>

  <vxe-modal ref="preview" title="预览" width="800" height="600" show-zoom transfer>
    <div class="preview">
      <el-image width="auto" :src="dialogImageUrl" fit="fill"></el-image>
    </div>
  </vxe-modal>

  <vxe-modal ref="xModal" title="拍照" transfer @close="stopNavigator">
    <template v-slot>
      <div class="camera">
        <div class="camera_outer" v-loading="loading">
          <video id="videoCamera" :width="videoWidth" :height="videoHeight" autoplay class="scaleX video"></video>
          <canvas style="display: none" id="canvasCamera" :width="videoWidth" :height="videoHeight"></canvas>
          <div v-if="imgSrc" class="img_bg_camera scaleX">
            <img :src="imgSrc" alt="" class="tx_img" />
          </div>
        </div>
        <div class="button_list">
          <el-button type="success" plain @click="getCompetence()">{{
              imgSrc ? "重新拍摄" : "打开摄像头"
            }}</el-button>
          <el-button type="primary" @click="setImage()" v-if="!imgSrc&&showButton">拍照</el-button>
          <el-button type="primary" @click="imgUpload()" v-if="imgSrc&&showButton">上传</el-button>
        </div>
      </div>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "VxeUpload",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
    limitCount: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
  computed: {
    ...mapState(["logo"]),
  },
  data() {
    return {
      action: `/sys/sysFile`,
      dialogImageUrl: "",
      hideUpload: false,
      headers: {
        Authorization: localStorage.token,
      },
      fileList: [],
      loading: false,
      openPhoto: false,
      videoWidth: 300,
      videoHeight: 300,
      imgSrc: "",
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      file: "",
      showButton: true,
      ...this.renderOpts,
    };
  },

  created() {
    let { row, column, data, property } = this.params;
    let value = "";
    if (this.type == "renderItemContent") {
      value = this.$Tools.getValueByfieldEvent(data, property);
    } else {
      value = row[column.property];
    }
    if (value) {
      this.fileList = [{
        name: value,
        url: `${this.action}/getFile/${value}`,
      }];
    }
  },

  methods: {
    beforeUpload() {},
    handleRemove(file) {
      this.fileList.forEach((img, index) => {
        if (file.url == img.url) {
          this.fileList.splice(index, 1);
        }
      });
      this.hideUpload = this.fileList.length >= this.limitCount;
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, "");
        });
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.$refs.preview.open();
    },
    handleDownload(file) {
      console.log(file);
    },
    onSuccess({ data }) {
      this.fileList.push({
        name: data,
        url: `${this.action}/getFile/${data}`,
      });
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, data);
        });
      }
      this.hideUpload = this.fileList.length >= this.limitCount;
      this.$message.success("上传成功");
    },
    onError(err) {
      console.log(err);
    },
    submitUpload() {
      this.$refs.upload.submit();
    },

    openPhotoEvent() {
      this.$refs.xModal.open();
      this.openPhoto = true;
    },

    // 调用权限（打开摄像头功能）
    getCompetence() {
      this.loading = true;
      this.imgSrc = "";
      let _this = this;
      this.thisCancas = document.getElementById("canvasCamera");
      this.thisContext = this.thisCancas.getContext("2d");
      this.thisVideo = document.getElementById("videoCamera");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)

          let getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;

          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }

          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      let constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: "scaleX(-1)",
        },
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in _this.thisVideo) {
            _this.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream);
          }

          _this.thisVideo.onloadedmetadata = function (e) {
            _this.loading = false;
            _this.thisVideo.play();
          };
          this.showButton = true
        })
        .catch((err) => {
          console.log(err.message)
          if (err.message.indexOf("Requested device not found") >= 0) {
            this.$message.error('未检测到摄像头');
            this.showButton = false
          }

          this.loading = false
        });
    },

    // 绘制图片（拍照功能）
    setImage() {
      // 点击，canvas画图
      this.thisContext.drawImage(
        this.thisVideo,
        0,
        0,
        this.videoWidth,
        this.videoHeight
      );

      // 获取图片base64链接
      let image = this.thisCancas.toDataURL("image/png");
      this.imgSrc = image;
      this.$emit("refreshDataList", this.imgSrc);
      this.stopNavigator();
      this.file = this.dataURLtoFile(image, "image.png");
    },

    // base64转文件

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },

    // 关闭摄像头
    stopNavigator() {
      if (this.thisVideo && this.thisVideo.srcObject && this.thisVideo.srcObject.getTracks) {
        this.thisVideo.srcObject.getTracks()[0].stop();
      }
    },

    // 上传图片
    imgUpload() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.$Axios.post(this.action, formData)
        .then(({ code, data, message }) => {
          if (code == 200) {
            this.fileList = [{
              name: data,
              url: `${this.action}/getFile/${data}`,
            }, ];

            if (this.events) {
              Object.keys(this.events).forEach((key, index) => {
                if (index == 0) this.events[key](this.params, data);
              });
            }
            this.$message.success("上传成功");
            this.hideUpload = this.fileList.length >= this.limitCount;
            this.$refs.xModal.close();
            this.stopNavigator()
          } else {
            this.$message.error(message);
          }
        });
    },
  },

  closeEvent() {},
};
</script>

<style lang="scss">
.upload {
  width: 150px;
  overflow: hidden;
}

.uploadImg {
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.hide {
  overflow: hidden;

  .el-upload--picture-card {
    display: none;
  }
}

.file {
  overflow: hidden;
}

.uploadBtn {
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.camera {
  text-align: center;
  position: relative;
}

.camera_outer {
  padding: 10px;
  width: 300px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.picture {
  position: relative;
  width: 300px;
  background: #fff;
}

.scaleX {
  transform: scaleX(-1);
}

.video {
  width: 300px;
  height: 300px;
  overflow: hidden;
  border: 1px dashed #d9d9d9;
  background: url("../../assets/images/icon_photo.png") center no-repeat;
  background-size: 150px auto;
}

.img_bg_camera {
  width: 302px;
  height: 302px;
  overflow: hidden;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 99;
  background: #fff;
  margin: 0 auto;
}

.button_list {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.preview {
  padding: 10px;
  text-align: center;

  .el-image {
    width: 100%;
  }
}
</style>
