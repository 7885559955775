<template>
<!-- 表格数据 -->
<div class="table">
  <vxe-grid ref="xGrid" v-bind="gridOptions" :loading="loading" :data="tableData" @toolbar-button-click="toolbarCustomEvent"></vxe-grid>
</div>
</template>

<script>
export default {
  name: "VxeAttachmentTable",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      loading: false,
      action: `/sys/sysFile`,
      tableData: [],
      ...this.renderOpts,
      gridOptions: {
        height: 300,
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              buttonRender: {
                name: '$ToolbarUploadBtn',
                props: {
                  content: "附件上传",
                  status: "primary",
                },
                events: {
                  click: (id) => {
                    let { data, property } = this.params;
                    data[property].push(id)
                    this.getLists();
                  }
                }
              }
            },
            {
              code: "batchDelete",
              name: "批量删除",
              status: "danger",
              events: {
                click: () => {
                  let records = this.$refs.xGrid.getCheckboxRecords();
                  if (!records.length) {
                    this.$message.error(this.$t("pleaseSelectDataToDelete"));
                  } else {
                    this.removeEvent(records)
                  }
                }
              }
            },
          ],
          zoom: false
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "fileRealName",
            title: "附件名称",
            minWidth: 160,
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
              lists: [
                // {
                //   status: "primary",
                //   label: "下载",
                //   eventName: "customEvent",
                //   events: {
                //     click: (row) => {
                //       this.downloadEvent(row)
                //     }
                //   }
                // },
                {
                  status: "danger",
                  label: "删除",
                  eventName: "customEvent",
                  events: {
                    click: (row) => {
                      this.removeEvent([row])
                    }
                  }
                }
              ]
            },
          },
        ],
        pagerConfig: {
          enabled: false
        }
      },
    }
  },

  created() {
    let { data, property } = this.params;
    if (!data[property]) {
      data[property] = [];
    } else {
      this.getLists();
    }
  },
  methods: {
    // 列表按钮组功能通用事件
    toolbarCustomEvent({ code, button }) {
      if (button.events) {
        Object.keys(button.events).forEach((key, index) => {
          if (index == 0) button.events[key](button);
        });
      }
    },

    // 获取类型树
    getLists() {
      this.loading = true;
      let { data, property } = this.params;
      this.$Axios.post('/sys/sysFile/listByIds', data[property]).then(({ code, data }) => {
        if (code === 200) {
          this.tableData = data;
        }
        this.loading = false;
      });
    },

    downloadEvent(row) {
      this.$Axios.get(`/sys/sysFile/getFile/${row.id}`, {
        responseType: 'arraybuffer',
      }).then(res => {
        this.$Tools.downloadFileByBlob(res, row.fileRealName)
      });
    },

    removeEvent(records) {
      this.$refs.xGrid.remove(records).then(({ rows }) => {
        let { data, property } = this.params;
        rows.forEach((row) => {
          this.$XEUtils.remove(data[property], item => item === row.id);
        });
      });
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
