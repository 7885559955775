<template>
<div style="border: 1px solid #ccc; position: relative;">
  <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
  <Editor style="height: 200px; min-height: 200px overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
</div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  components: { Editor, Toolbar },
  name: "VxeEdit",
  props: {
    renderOpts: {
      type: Object,
      default: () => {
        return {
          value: "value",
          label: "label",
        }
      }
    },
    params: Object,
    type: String,
  },
  data() {
    let action = `/sys/sysFile`;
    return {
      editor: null,
      toolbarConfig: {
        // excludeKeys:['uploadVideo']
      },
      editorConfig: {
        MENU_CONF: {
          uploadImage: { // 上传图片
            server: action,
            fieldName: 'file',
            maxFileSize: 5 * 1024 * 1024, // 5M
            meta: {
              Authorization: localStorage.token
            },
            onBeforeUpload(file) {
              return file
            },
            customInsert(res, insertFn) {
              // 从 res 中找到 url alt href ，然后插图图片
              insertFn(`${action}/getFile/${res.data}`, '', '')
            }
          },
          uploadVideo: { // 传视频
            server: action,
            fieldName: 'file',
            maxFileSize: 100 * 1024 * 1024, // 100M
            meta: {
              Authorization: localStorage.token
            },
            onBeforeUpload(file) {
              return file
            },
            customInsert(res, insertFn) {
              insertFn(`${action}/getFile/${res.data}`)
            }
          },
        }
      },

      mode: 'default', // or 'simple'
    };
  },
  computed: {
    html: {
      get() {
        let { row, column, data, property } = this.params;
        return this.type == "renderItemContent" ? data[property] : row[column.property];
      },
      set(newValue) {
        let { row, column, data, property } = this.params;
        if (this.type == "renderItemContent") {
          this.$set(data, property, newValue);
        } else {
          this.$set(row, column.property, newValue);
        }
      }
    },
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      // 所有菜单
      // console.log(editor.getAllMenuKeys())
    }
  },
  mounted() {

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }

};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
