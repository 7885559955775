<template>
<vxe-select v-model="params.value" placeholder="请选择" clearable :options="params.options" @change="changeEvent"></vxe-select>
</template>

<script>
export default {
  name: "ToolbarSelect",
  props: {
    params: Object,
    type: String,
  },
  data() {
    return {
      ...this.params
    }
  },
  methods: {
    changeEvent({ value }) {
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](value);
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
