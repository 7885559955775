<template>
<vxe-pulldown ref="xDown" transfer>
  <template #default>
    <vxe-input v-model="filterText" :placeholder="placeholder" @focus="focusEvent" @clear="clearEvent"></vxe-input>
  </template>
  <template #dropdown>
    <div class="dropdown">
      <div class="dropdown" ref="map"></div>
      <div class="search">
        <input />
        <el-select v-model="searchValue" multiple filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
  </template>
</vxe-pulldown>
<!-- <script src="https://map.qq.com/api/gljs?v=1.exp&key=VFZBZ-76S3P-NQBDO-L3T52-TH27E-HJB6Y"></script> -->
</template>

<script>

// import TMap from 'TMap';
export default {
  name: "VxeSelectMap",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      filterText: "",
      options: [],
      searchValue: [],
      loading: false,
    };
  },
  computed: {
    placeholder() {
      return this.renderOpts.props && this.renderOpts.props.placeholder || "请选择"
    },
  },
  created() {

  },
  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        // 搜索接口
        this.$Axios.get(`/map/suggestion/?keyword=${this.mapKeyword}&key=VFZBZ-76S3P-NQBDO-L3T52-TH27E-HJB6Y`).then((res) => {
          this.loading = false;
        });
      } else {
        this.options = [];
      }
    },
    // initMap() {
    //   let _this = this
    //   let center = new TMap.LatLng(39.984120, 116.307484)
    //   let map = new TMap.Map(this.$refs.map, {
    //     center: center,
    //     zoom: 17.2,
    //     pitch: 43.5,
    //     rotation: 45,
    //   })
    //   let markerLayer = new TMap.MultiMarker({
    //     map: map,
    //   });

    //   let clickHandler = function (evt) {
    //     let lat = evt.latLng.getLat().toFixed(6);
    //     let lng = evt.latLng.getLng().toFixed(6);
    //     markerLayer.setGeometries([])
    //     markerLayer.add([{
    //       "id": "1",
    //       "styleId": 'myStyle',
    //       "position": new TMap.LatLng(lat, lng),
    //       "properties": {
    //         title: "marker3"
    //       }
    //     }])

    //     _this.$Axios.get(`/geocoder/?location=${lat},${lng}&key=VFZBZ-76S3P-NQBDO-L3T52-TH27E-HJB6Y`).then((res) => {
    //     });

    //   }
    //   map.on("click", clickHandler)
    // },

    focusEvent() {
      this.$refs.xDown.showPanel();
      this.$nextTick(() => {
        this.initMap()
      })

    },

    clearEvent() {
      this.filterText = ""
    },

    resetEvent() {
      this.loading = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  width: 686.81px;
  height: 400px;
  border: 1px solid #ccc;
  overflow: hidden;
  position: relative;
}

.search {
  position: absolute;
  height: 50px;
  border: 1px solid #ccc;
  left: 0;
  top: 0;
  width: 200px;
  z-index: 999;
}
</style>
