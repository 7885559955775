<template>
<div v-if="type != 'renderCell'">
  <vxe-input v-model="value" placeholder="请选择图标" @focus="showModal=true"></vxe-input>
  <vxe-modal title="选择图标" width="800" show-zoom v-model="showModal">
    <template v-slot>
      <div class="wrapper">
        <ul class="fontIcon">
          <li v-for="(item,index) in tableData" :key="index" @click="selectIcon(item.className)">
            <i :class="'iconfont ' + item.className"></i>
            <p>{{item.name}}</p>
          </li>
        </ul>
        <div class="page">
          <vxe-pager :current-page="tablePage.currentPage" :page-size="tablePage.pageSize" :total="tableData.length" @page-change="pageChange">
          </vxe-pager>
        </div>
      </div>
    </template>
  </vxe-modal>
</div>
<div class="iconfonts" v-else>
  <i class="iconfont" :class="value"></i>
  <span>{{value}}</span>
</div>
</template>

<script>
import iconFonts from "@/utils/fontData"
export default {
  name: "VxeSelectIcon",
  props: ["renderOpts", "params", "type"],
  data() {
    return {
      showModal: false,
      tableData: iconFonts,
      tablePage: {
        currentPage: 1,
        pageSize: 20,
      },
    }
  },
  computed: {
    value() {
      let { row, column, data, property } = this.params;
      return this.type == "renderItemContent" ? data[property] : row[column.property];
    },
  },
  methods: {
    //选图标
    selectIcon(item) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = item : row[column.property] = item;
      this.showModal = false;
    },

    //分页
    pageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      let arr = [...iconFonts];
      this.tableData = arr.splice(
        (currentPage - 1) * this.tablePage.pageSize,
        this.tablePage.pageSize
      );
    },
  }
};
</script>

<style lang="scss" scoped>
.iconfonts {
  span {
    padding: 0 10px;
  }
}

.wrapper {
  height: 560px;
  display: flex;
  justify-content: center;
  align-content: center;

  .fontIcon {
    flex: 1;
    border-left: 1px solid #f2f2f2;
    border-top: 1px solid #f2f2f2;
    overflow: hidden auto;

    li {
      float: left;
      width: 25%;
      height: 100px;
      padding: 20px;
      text-align: center;
      border-right: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      box-sizing: border-box;
      cursor: pointer;

      i {
        font-size: 30px;
      }

      &:hover {
        color: #409EFF;
      }
    }
  }
}
</style>
