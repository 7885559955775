<template>
<span v-if="type != 'renderCell'">
  <vxe-pulldown ref="xDown" transfer>
    <template #default>
      <vxe-input v-model="value" suffix-icon="fa fa-search" :disabled="props.disabled" :placeholder="props.placeholder" @focus="focusEvent" @clear="clearEvent"></vxe-input>
    </template>
    <template #dropdown>
      <div class="dropdown">
        <el-tree ref="tree" v-loading="loading" :data="options" :props="defaultProps" :default-expand-all="expandAll" :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="getTreeNode"></el-tree>
      </div>
    </template>
  </vxe-pulldown>
</span>
<span v-else>
  {{ currNode.name }}
</span>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "VxeSelectParent",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      loading: false,
      defaultProps: {
        label: "name",
        value: "id",
      },
      value: "",
      options: [],
      ...this.renderOpts,
    }
  },
  computed: {
    currNode() {
      let { row, column, data, property } = this.params;
      let nodeId = this.type == "renderItemContent" ? data[property] : row[column.property];
      let node = this.options.length ? this.$XEUtils.findTree(this.options, item => item.id === nodeId) : null;
      return node ? node.item : {};
    },
  },
  watch: {
    currNode: {
      immediate: true,
      handler(val) {
        this.value = val.name;
      }
    },
    value(val) {
      this.value = val
      if (this.$refs.tree) {
        this.$refs.tree.filter(val);
      }
    },
  },
  created() {
    if (this.service) {
      this.getLists();
    }
  },
  methods: {
    ...mapActions(['getTreeService']),

    // 获取数据
    getLists() {
      this.loading = true;
      this.getTreeService({
        service: this.service,
        params: this.defaultParams || {}
      }).then(({ code, data }) => {
        if (code == 200) {
          if (this.service == "/sys/sysAppResource") {
            this.options = data || [];
          } else {
            this.options = this.$XEUtils.toArrayTree(data, { strict: true, parentKey: 'parentId', key: 'id' }) || [];
          }
        }
        this.loading = false;
      });
    },

    focusEvent() {
      this.$refs.xDown.showPanel();
    },

    // 节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 获取节点
    getTreeNode(node) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = node.id : row[column.property] = node.id;
      this.value = node.name;
      // 触发外部事件
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, node);
        });
      }
      this.$refs.xDown && this.$refs.xDown.hidePanel();
    },

    clearEvent() {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = null : row[column.property] = null;
      this.value = null;
      if (this.events) {
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, {});
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  width: 100%;
  padding: 5px 0;
  max-height: 200px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
</style>
