<template>
<vxe-select v-model="value" :placeholder="props.placeholder" :clearable="props.clearable" :options="options" @change="changeEvent"></vxe-select>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "VxeSelectList",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      defaultProps: {
        label: "name",
        value: "id",
      },
      expandAll: false,
      loading: false,
      currNode: {},
      options: [],
      ...this.renderOpts,
    };
  },
  computed: {
    value: {
      get() {
        let { row, column, data, property } = this.params;
        return this.type == "renderItemContent" ? data[property] : row[column.property];
      },
      set(newValue) {
        let { row, column, data, property } = this.params;
        if (this.type == "renderItemContent") {
          this.$set(data, property, newValue);
        } else {
          this.$set(row, column.property, newValue);
        }
      }
    },
  },
  watch: {
    defaultParams: {
      immediate: true,
      deep: true,
      handler() {
        if (this.type == "renderItemContent") {
          this.getLists();
        }
      }
    }
  },
  methods: {
    ...mapActions(['findByEventService']),
    // 获取数据
    getLists() {
      let { data } = this.params;
      if (data.organizationId) {
        data.parentId = data.organizationId;
      }
      if (!data.parentId) {
        this.clearEvent();
        return;
      }
      this.loading = true;
      this.findByEventService({
        service: this.service,
        params: this.defaultParams || {
          parentId : data.parentId
        }
      }).then(({ code, data, }) => {
        if (code == 200) {
          // 数据特殊处理将形参（name,id）,转换为固定结构(value,label),并将数据字段存储到缓存中;
          this.options = this.$Tools.treeDataEvent(data, this.defaultProps) || [];
        }
      });
    },

    // 获取节点
    changeEvent({ value }) {
      let { row, column, data, property } = this.params;
      if (this.type == "renderItemContent") {
        data[property] = this.props.multiple ? [...value] : value
      } else {
        row[column.property] = this.props.multiple ? [...value] : value
      }
      // 触发外部事件
      if (this.events) {
        let nodeId = this.type == "renderItemContent" ? data[property] : row[column.property];
        let currNode = this.$Tools.getNodeById(this.options || [], nodeId);
        Object.keys(this.events).forEach((key, index) => {
          if (index == 0) this.events[key](this.params, currNode);
        });
      }
    },

    clearEvent() {
      if (this.type == "renderItemContent") {
        this.params.data[this.params.property] = null;
      } else {
        this.params.row[this.params.column.property] = null;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  width: 100%;
  padding: 10px 0;
  max-height: 300px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);

  .table {
    padding: 0;
  }
}
</style>
