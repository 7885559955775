import Vue from "vue";

import Tree from "./Tree";
import VxeOrgTree from "./VxeOrgTree";
import VxeTreeTableComponent from "./VxeTreeTable";
import VxeBasicTableComponent from "./VxeBasicTable";

Vue.component("Tree", Tree);
Vue.component("VxeOrgTree", VxeOrgTree);
Vue.component("VxeTreeTable", VxeTreeTableComponent);
Vue.component("VxeBasicTable", VxeBasicTableComponent);