<template>
<vxe-input v-model="value" :placeholder="props.placeholder" type="number" maxlength="11" :disabled="props.disabled" :controls="false" @input="inputEvent"></vxe-input>
</template>

<script>
export default {
  name: "VxePhoneInput",
  props: {
    renderOpts: Object,
    params: Object,
    type: String,
  },
  data() {
    return {
      ...this.renderOpts,
    }
  },
  computed: {
    value: {
      get() {
        let { row, column, data, property } = this.params;
        return this.type == "renderItemContent" ? data[property] : row[column.property] || "";
      },
      set(newValue) {
        let { row, column, data, property } = this.params;
        this.type == "renderItemContent" ? data[property] = newValue : row[column.property] = newValue;
      }
    }
  },
  created() {
    // let { row, column, data, property } = this.params;
    // this.value = this.type == "renderItemContent" ? data[property] : row[column.property] || "";
    this.setItemRules(this.value)
  },
  methods: {
    setItemRules(value) {
      let { $form, item, property } = this.params;
      let itemRules = [
        { required: true, message: "请输入有效的联系电话" },
        {
          validator: ({ itemValue }) => {
            if (itemValue && !this.$Tools.regs.phone.test(itemValue)) {
              return new Error(`${this.params.item.title}格式不正确，请重新输入!`);
            }
          },
        }
      ]
      if (value || item.itemRender.props && item.itemRender.props.required) {
        $form.rules[property] = itemRules;
      } else {
        $form.rules[property] = [];
      }
    },
    inputEvent({ value }) {
      let { row, column, data, property } = this.params;
      this.type == "renderItemContent" ? data[property] = value : row[column.property] = value;
      this.setItemRules(value);
    },
  }
}
</script>

<style>

</style>
