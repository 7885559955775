<template>
<vxe-pulldown ref="xDown" transfer>
  <template #default>
    <vxe-input v-model="value" suffix-icon="fa fa-search" :placeholder="placeholder" @focus="focusEvent" @keyup="keyupEvent" @clear="clearEvent"></vxe-input>
  </template>
  <template #dropdown>
    <vxe-list ref="dropdown" height="auto" class="dropdown" :data="tableData">
      <template #default="{ items }">
        <ul v-if="tableData.length">
          <li v-for="item in items" :key="item.value" @click="selectEvent(item)">
            {{ item.label }}
          </li>
        </ul>
        <ul v-else>
          <li class="empty">无匹配数据</li>
        </ul>
      </template>
    </vxe-list>
  </template>
</vxe-pulldown>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "VxeSelectPulldown",
  props: {
    renderOpts: {
      type: Object,
      default: () => {
        return {
          value: "id",
          label: "name",
        };
      },
    },
    params: Object,
    type: String,
  },
  data() {
    return {
      value: "",
      tableData: [],
      originData: [],
    };
  },
  computed: {
    height() {
      return this.tableData.length ? this.tableData.length * 30 + 10 : "auto";
    },
    placeholder() {
      return (
        (this.renderOpts.props && this.renderOpts.props.placeholder) || "请选择"
      );
    },
  },
  created() {
    this.getLists();
  },
  methods: {
    ...mapActions(["findByEventService"]),
    // 获取数据
    getLists() {
      this.loading = true;
      this.findByEventService({
        service: this.renderOpts.service,
        params: this.renderOpts.params || {},
      }).then(({ code, data, message }) => {
        if (code == 200) {
          // 数据特殊处理将形参（name,id）,转换为固定结构(value,label),并将数据字段存储到缓存中;
          this.originData = this.tableData = this.$Tools.treeDataEvent(data, this.renderOpts.defaultProps);
          let { property } = this.params;
          if (this.params.data[property]) {
            let currNode = this.$Tools.getNodeById(
              this.originData,
              this.params.data[property]
            );
            this.value = currNode && currNode.label ? currNode.label : "";
          }
        }
      });
    },

    focusEvent() {
      this.$refs.xDown.showPanel();
    },

    keyupEvent({ value }) {
      this.tableData = value ? this.originData.filter((item) => item.label.indexOf(value) > -1) : this.originData;
      this.$refs.xDown.showPanel();
    },

    selectEvent(item) {
      this.value = item.label;
      this.$refs.xDown.hidePanel().then(() => {
        this.tableData = this.originData;
      });
    },

    clearEvent() {
      if (this.type == "renderItemContent") {
        this.params.data[this.params.property] = null;
      } else {
        this.params.row[this.params.column.property] = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  width: 100%;
  max-height: 200px;
  overflow: auto;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);

  ul {
    padding: 5px 0;
    border-radius: 5px;
    overflow: hidden;

    li {
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      cursor: pointer;

      &.empty {
        text-align: center;
      }

      &:hover {
        background-color: #f5f7fa;
      }
    }
  }
}
</style>
