<template>
<div class="wrapper">
  <!-- 查询功能 -->
  <div class="search" v-if="searchOptions.items && searchOptions.items.length">
    <vxe-form v-bind="searchOptions" @submit="searchEvent" @reset="searchResetEvent"></vxe-form>
  </div>
  <!-- 表格数据 -->
  <div class="table">
    <vxe-grid ref="xGrid" height="auto" v-bind="gridOptions" :pager-config="tablePage" :loading="loading" :data="tableData" @toolbar-button-click="toolbarCustomEvent" @page-change="pageChange" @menu-click="contextMenuClickEvent" v-on="gridOptions.events"></vxe-grid>
  </div>
  <!-- 新增、编辑功能 -->
  <vxe-modal ref="xModal" v-bind="formOptions" :title="formData.id ? $t('editAndSave') : $t('createAndSave')" show-zoom>
    <template v-slot>
      <vxe-form ref="xForm" v-bind="formOptions" :data="formData" v-loading="loading" @submit="submitEvent" @reset="closeModal(false)"></vxe-form>
    </template>
  </vxe-modal>
</div>
</template>

<script>
import { mapActions } from "vuex";
import XEUtils from "xe-utils";
export default {
  props: {
    service: String,
    defaultProps: {
      type: Object,
      default: () => {
        return {
          value: "code",
          label: "name",
        };
      },
    },
    gridOptions: {
      type: Object,
      default: () => {
        return {
          columns: [],
          data: {},
        };
      },
    },
    formOptions: {
      type: Object,
      default: () => {
        return {
          width: "1000",
          titleWidth: "100",
          items: [],
          data: {}
        };
      },
    },
    searchOptions: {
      type: Object,
      default: () => {
        return {
          items: [],
          data: {}
        };
      },
    },
    // 是否默认加载数据 FALSE 为默认加载,TRUE 为外界触发加载
    defaultTrigger: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  data() {
    return {
      serviceUrl: "",
      loading: false,
      tableData: [],
      originData: [],
      formData: {},
      // 分页的参数配置
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.serviceUrl = this.service || this.$route.meta.service;
    let { toolbarConfig, editRules, pagerConfig } = this.gridOptions;
    let permissButtons = this.$Tools.getBtnPermiss(this.$route);
    toolbarConfig.buttons = toolbarConfig.buttons || [
      { code: "create", name: "新增", status: "primary" },
      { code: "batchDelete", name: "批量删除", status: "danger" },
    ]
    toolbarConfig.buttons.map(btn => {
      let node = XEUtils.filterTree(permissButtons, item => item.code === `${this.$route.name}.${btn.code}`)[0];
      if (node) {
        btn.name = node.name || btn.name;
        btn.visible = true
      } else {
        btn.visible = false;
      }
    });

    let { rules } = this.formOptions;
    // 分页信息
    if (pagerConfig) this.tablePage = pagerConfig
    // 根据新增功能中的字段增加表格中的校验
    this.gridOptions.editRules = editRules || rules;
    // 获取当前页面所需要的数据字典key
    this.getSysDictEvent();
    if (this.defaultTrigger) {
      this.getLists();
    }
  },
  methods: {
    ...mapActions([
      "getSysDictService",
      "getListService",
      "removeEventService",
      "submitEventService",
      "queryEventService",
      "importAllEventService",
      "exportAllEventService",
    ]),

    // 获取字典key
    getTypeCodeEvent(dicts, columns) {
      columns.forEach(item => {
        let render = item.itemRender || item.editRender || item.cellRender;
        if (render && render.optionTypeCode && dicts.indexOf(render.optionTypeCode) == -1) {
          dicts.push(render.optionTypeCode);
        }
        if (item.children && item.children.length) {
          this.getTypeCodeEvent(dicts, item.children)
        }
      });
      return dicts;
    },

    // 获取数据字典
    getSysDictEvent() {
      let dictTypeCodes = this.getTypeCodeEvent([], [...this.gridOptions.columns, ...this.formOptions.items, ...this.searchOptions.items]);
      if (!dictTypeCodes.length) {
        return;
      }
      this.getSysDictService({
          params: dictTypeCodes
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.$Tools.buildDataByDicts(this.gridOptions.columns, data, this.defaultProps);
            this.$Tools.buildDataByDicts(this.formOptions.items, data, this.defaultProps);
            this.searchOptions.items = [...this.$Tools.buildDataByDicts(this.searchOptions.items, data, this.defaultProps)];
          }
        });
    },

    // 获取类型树
    getLists() {
      this.loading = true;
      this.getListService({
          service: this.serviceUrl,
          tablePage: this.tablePage,
          params: this.searchOptions.data || {},
        })
        .then(({ code, data, dicts }) => {
          if (code == 200) {
            this.tableData = data && data.records || [];
            this.originData = data && data.records || [];
            this.tablePage.total = data && data.total || 0;
          }
          this.resetEvent();
        }).catch(err => {
          this.resetEvent();
        });
    },

    // 分页功能
    pageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.getLists();
    },

    // 列表按钮组功能通用事件
    toolbarCustomEvent({ code, button }) {
      if (button.events) {
        Object.keys(button.events).forEach((key, index) => {
          if (index == 0) button.events[key](button);
        });
      } else {
        switch (code) {
          case "create":
            this.insertEvent();
            break;
          case "batchDelete":
            this.batchDeleteEvent();
            break;
          default:
            if (button.events) {
              Object.keys(button.events).forEach((key, index) => {
                if (index == 0) button.events[key](code);
              });
            }
            break;
        }
      }
    },

    // 新增事件
    insertEvent() {
      this.formData = { ...this.formOptions.data };
      this.$refs.xModal.open();
    },

    // 编辑事件
    queryEvent(row, btn) {
      this.loading = true;
      this.queryEventService({
          service: this.serviceUrl,
          params: row,
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.formData = data;
            this.$refs.xModal.open();
            if (btn && btn.events) {
              Object.keys(btn.events).forEach((key, index) => {
                if (index == 0) btn.events[key](data);
              });
            }
          }
          this.resetEvent();
        })
        .catch((err) => {
          this.resetEvent();
        });
    },

    // 保存提交事件
    async submitEvent(row) {
      if (row.data) {
        this.$refs["xForm"].validate((valid) => {
          if (!valid) {
            this.submitData(row.data)
          }
        });
      } else {
        const errMap = await this.$refs.xGrid.validate(row).catch(errMap => errMap);
        if (errMap) {
          this.$message.error("请完善数据内容");
        } else {
          this.$refs.xGrid.clearActived().then(() => {
            this.submitData(row)
          })
        }
      }
    },

    // 提交数据
    submitData(row) {
      if (this.loading) return;
      this.loading = true;
      this.submitEventService({
        service: this.serviceUrl,
        params: row,
      }).then(({ code }) => {
        if (code == 200) {
          this.getLists();
          this.$message.success(row.id ? "更新成功" : "保存成功");
          this.closeModal();
        } else {
          this.$refs.xGrid.setActiveRow(row)
        }
        this.resetEvent();
      }).catch((err) => {
        this.resetEvent();
      });
    },

    // 取消事件
    cancelEvent(row) {
      this.$refs.xGrid.clearActived().then(() => {
        this.$refs.xGrid.revertData();
      });
    },

    // 搜索功能
    searchEvent() {
      this.tablePage.currentPage = 1;
      this.getLists();
    },

    // 搜索重置功能
    searchResetEvent() {
      this.getLists();
    },

    // 关闭模态对话框
    closeModal(bool) {
      if (bool) this.getLists();
      if (this.$refs.xForm) {
        this.$refs.xForm.reset();
      }
      if (this.$refs.xModal) {
        this.$refs.xModal.close();
      }
    },

    // 批量删除
    batchDeleteEvent() {
      let records = this.$refs.xGrid.getCheckboxRecords();
      if (!records.length) {
        this.$message.error(this.$t("pleaseSelectDataToDelete"));
      } else {
        let ids = [];
        records.forEach((item) => {
          ids.push(item.id);
        });
        this.removeEvent(ids);
      }
    },

    // 删除事件
    removeEvent(ids) {
      this.$confirm(this.$t("isDelete"), this.$t("confirmTitle")).then(
        (type) => {
          if (type === "confirm") {
            this.loading = true;
            this.removeEventService({
                service: this.serviceUrl,
                params: ids,
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$message.success(this.$t("deleteSuccess"));
                  this.getLists();
                  this.resetEvent();
                }
                this.resetEvent();
              })
              .catch((err) => {
                this.resetEvent();
              });
          }
        }
      );
    },

    resetEvent() {
      this.loading = false;
    },

    // 鼠标右键功能
    contextMenuClickEvent({ menu, row, column }) {
      const $xGrid = this.$refs.xGrid;
      switch (menu.code) {
        case 'refresh':
          this.getLists();
          break
        case 'exportData':
          this.exportAllEvent()
          break
        default:
          break;
      }
    },

    // 批量导入
    // importAllEvent(service, params) {
    //   this.importAllEventService({
    //     service: service || this.serviceUrl,
    //     params : params
    //   });
    // },
    // 导入功能
    importAllEvent(service, { communityId, communityName, organizationId }) {
      this.$refs.xGrid.readFile({
        types: ["xls", "xlsx"],
      }).then((params) => {
        this.loading = true;
        const { file, files } = params;
        // 开始导入
        this.importAllEventService({
            service: service,
            communityId,
            communityName,
            organizationId,
            file,
          })
          .then(({ data }) => {
            if (data.code == 200) {
              // 自定义导入，将导入失败的数据进行导出
              this.customExportEvent(data.data || [])
            } else {
              this.$message.error(data.message);
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(err.message);
          });
      });
    },

    // 批量导出
    exportAllEvent(options) {
      this.$refs.xGrid.exportData({
        filename: '导出',
        sheetName: 'Sheet1',
        type: 'xlsx'
      })

      // this.exportAllEventService({
      //   service: this.serviceUrl,
      //   params: {
      //     condition: this.data,
      //     exportColumns: options.columns,
      //   },
      // });
    },

    // 批量导出
    customExportAllEvent(service, params) {
      this.exportAllEventService({
        service: service,
        params: params,
      }).then(res => {
        if (!res || !res.data) {
          this.$message({
            type: 'error',
            message: "导出失败！"
          });
          return;
        }
        let blob = new Blob([res.data], {
          type: "application/msexcel;charset=utf-8",
        });
        let downloadFilename = "资产" + ".xlsx"; //设置导出的文件名

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //兼容ie浏览器
          window.navigator.msSaveOrOpenBlob(blob, downloadFilename)
        } else {
          //谷歌,火狐等浏览器
          let url = window.URL.createObjectURL(blob);
          let downloadElement = document.createElement("a");
          downloadElement.style.display = "none";
          downloadElement.href = url;
          downloadElement.download = downloadFilename;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(url);
        }
        this.$message({ type: "success", message: '导出成功！' });
      });
    },

    // 自定义导出
    customExportEvent(data) {
      if (!data.length) {
        this.$message.success("导入成功");
        return;
      }
      this.tableData = data;
      this.$confirm(`有【${data.length}】条数据导入失败,是否下载失败的数据?`, this.$t("confirmTitle"), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then((type) => {
        if (type == "confirm") {
          this.$refs.xGrid.exportData({
            type: 'xlsx',
            filename: '导出失败的数据',
            isHeader: true,
            useStyle: true,
            data: data
          })
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
