import Vue from "vue";
import Vuex from "vuex";
import Logos from "../../LogoConfig";
import Router from "@/router";
import createPersistedState from "vuex-persistedstate";
import Public from "./Modules/Public";
import WebSocket from "./Modules/WebSocket";
import Login from "./Modules/Login";

// 根据当前环境和访问地址，动态设置LOGO
let logo = Logos[0];
if (process.env.NODE_ENV == "production") {
    logo = Logos.filter((item) => item.origin == location.origin)[0] || Logos[0];
}
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        logo: logo,
        isCollapse: false,
        menuLists: [],
        wellCome: {
            id: 0,
            code: "WellCome",
            name: "欢迎页",
            fullName: "首页,欢迎页",
            linkType: "1",
            linkAddress: "Pages/Home/WellCome",
            index: "1-0",
            type: "1",
            children: [],
        },
        desktop: {
            id: 0,
            code: "Desktop",
            name: "工作台",
            fullName: "首页,工作台",
            linkType: "1",
            linkAddress: "Pages/Home/Desktop",
            index: "1-1",
            type: "1",
            children: [],
        },
        tabs: [],
        tabIndex: "0",
        activeMenu: null,
        dictionaries: {},
    },
    mutations: {
        // 全局处理路由跳转
        goToPage(state, item) {
            state.tabIndex = item.index;
            state.activeMenu = item;
            let tabs = localStorage.tabs ? JSON.parse(localStorage.tabs) : [];
            let flag = false;
            if (tabs.length) {
                for (let tab of tabs) {
                    if (tab.index == item.index) {
                        flag = true;
                        break;
                    }
                }
            } else {
                state.tabs = [];
            }
            if (!flag) {
                state.tabs.push(item);
                localStorage.tabs = JSON.stringify(state.tabs);
            }
            Router.push({
                name: item.code,
            });
        },

        // 删除页面上的选项卡
        removeTabEvent(state, tabIndex) {
            let tabs = localStorage.tabs ? JSON.parse(localStorage.tabs) : [];
            tabs.forEach((tab, index) => {
                if (tab.index === tabIndex) {
                    let nextTab = tabs[index + 1] || tabs[index - 1];
                    if (nextTab) {
                        state.tabIndex = nextTab.index;
                        state.activeMenu = nextTab;
                        tabs.splice(index, 1);
                        state.tabs.splice(index, 1);
                        localStorage.tabs = JSON.stringify(tabs);
                        Router.replace({
                            name: nextTab.code,
                        });
                    }
                }
            });
        },

        // 删除页面上的选项卡
        removeAllTab(state) {
            let tabs = localStorage.tabs ? JSON.parse(localStorage.tabs) : [];
            let resources = localStorage.resources ?
                JSON.parse(localStorage.resources) : [];
            let desktop = resources[0] ? resources[0].children[0] : state.desktop;
            if (desktop) {
                state.tabIndex = desktop.index;
                state.activeMenu = desktop;
                tabs = [desktop];
                state.tabs = [desktop];
                localStorage.tabs = JSON.stringify(tabs);
                Router.replace({
                    name: desktop.code,
                });
            }
        },

        goBack() {
            Router.back(-1);
        },
    },
    actions: {},
    modules: {
        Public,
        WebSocket,
        Login,
    },
    plugins: [
        createPersistedState({
            storage: window.localStorage,
            reducer(val) {
                return {
                    // 需要保持数据持久化的内容，只储存state中的部分数据
                    logo: val.logo,
                    isCollapse: val.isCollapse,
                    tabs: val.tabs,
                    tabIndex: val.tabIndex,
                    activeMenu: val.activeMenu,
                    selectionId: val.selectionId,
                    dictionaries: val.dictionaries,
                };
            },
        }),
    ],
});