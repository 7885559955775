import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

//获取原型对象上的replace函数
const originalReplace = VueRouter.prototype.replace;
//修改原型对象中的replace方法
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
};

const constRoutes = [{
        path: "/",
        redirect: {
            name: "Login",
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import ("@/views/Pages/Login/Login.vue"),
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import ("@/views/Pages/Login/Register.vue"),
    },
    {
        path: "/layout",
        name: "Layout",
        component: () =>
            import ("@/views/Layout/Layout.vue"),
        children: [],
    },
    {
        path: "/error",
        name: "NetWorkError",
        meta: {
            title: "系统异常",
        },
        component: () =>
            import ("@/views/Pages/NetworkError/NetworkError.vue"),
    },
    {
        path: "/main",
        name: "Main",
        component: () =>
            import ("@/views/Layout/Main.vue"),
        children: [],
    },
    {
        path: "/404",
        name: "404",
        meta: {
            title: "404",
        },
        component: () =>
            import ("@/views/Pages/404/404.vue"),
    },
    {
        path: "**",
        redirect: {
            name: "404",
        },
    },
];

// 路由配置
const Router = new VueRouter({
    // mode: 'hash',
    // linkActiveClass: 'active',
    routes: constRoutes,
});

Router.$addRoutes = (params) => {
    Router.matcher = new VueRouter().matcher;
    Router.addRoutes(params);
};

// 全局路由前置守卫
Router.beforeEach((to, from, next) => {
    // to: Route: 即将要进入的目标 路由对象
    // from: Route: 当前导航正要离开的路由
    // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

    // 获取本地存储的userToken  let token = localStorage.token;
    // 未登录状态；当路由到nextRoute指定页时，跳转至login
    let Layout = constRoutes[3].children;
    const nextRoute = ["Login", "Register"];
    const token = localStorage.token;
    const Resources = localStorage.resources ? JSON.parse(localStorage.resources) : [];
    if (!token || !Resources.length) {
        localStorage.clear();
    }
    if (nextRoute.indexOf(to.name) < 0 && !token) {
        next({
            name: "Login",
        });
    } else if (token && !Layout.length) {
        Resources.forEach((resource) => {
            let obj = {
                path: `/${resource.code.toLowerCase()}`,
                name: resource.code,
                redirect: {
                    name: resource.children && resource.children.length ? resource.children[0].code : "",
                },
                component: () =>
                    import ("@/views/Layout/Main.vue"),
                children: [],
            };
            Layout.push(structureRouter(obj, resource));
        });
        localStorage.resources = JSON.stringify(Resources);
        Router.$addRoutes([...constRoutes]);
        Store.commit("goToPage", Store.state.activeMenu);
    } else {
        next();
    }
});

const structureRouter = (obj, resource) => {
    if (resource.children && resource.children.length) {
        resource.children.forEach((item) => {
            if (item.type == 1 && item.children && item.children.length) {
                structureRouter(obj, item)
            }
            if (item.type == 2) {
                obj.children.push({
                    path: `/${resource.code.toLowerCase()}/${item.code.toLowerCase()}`,
                    name: item.code,
                    meta: {
                        service: item.service
                    },
                    component: () => item.linkType == 1 ?
                        import (`@/views/${item.linkAddress}/Index`) : import (`@/views/Pages/ThirdParty/Index`)
                });
            }
        });
    }
    return obj;
};

//全局路由后置守卫
Router.afterEach((to, from) => {});

export default Router;